import { Injectable } from "@angular/core";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { WindowSessionStorageNamesEn } from "@core-sub/WindowSessionStorageNamesEn";
import { BiStore } from "@globals/classes/BiStore";
import { SenderExt } from "@models-sub/ext/SenderExt";
import { EnrollmentAddressDtoExt } from "@shared-sub/components/en-address-selector/EnrollmentAddressDtoExt";
import { tap } from "rxjs";

interface EnrollmentSharedData {
  address: EnrollmentAddressDtoExt;
  unselectedSenders: SenderExt[];
}

@Injectable({
  providedIn: "root"
})
export class EnrollmentStepsSharedService extends BiStore<EnrollmentSharedData> {
  constructor(private enrollmentService: EnrollmentService) {
    super(
      sessionStorage.getItem(WindowSessionStorageNamesEn.enrollmentAddressAndName)
        ? JSON.parse(sessionStorage.getItem(WindowSessionStorageNamesEn.enrollmentAddressAndName))
        : {
            address: undefined,
            unselectedSenders: []
          }
    );
  }

  public refreshOperatorInfo(countryId: number) {
    return this.enrollmentService.getOperatorInfo(countryId).pipe(
      tap(data => {
        this.state.next({ ...this.state.value, address: data.address });
      })
    );
  }

  public setStateAndUseSessionStorage(stateData: EnrollmentSharedData) {
    this.state.next({ ...this.state.value, address: stateData.address });
    sessionStorage.setItem(WindowSessionStorageNamesEn.enrollmentAddressAndName, JSON.stringify({ address: stateData.address }));
  }

  public clearAddress() {
    this.state.next({ ...this.state.value, address: undefined });
    sessionStorage.removeItem(WindowSessionStorageNamesEn.enrollmentAddressAndName);
  }
}
