<button class="en-expanding-panel" (click)="toggleExpand()" [class.expanded]="expanded">
  <div class="en-expanding-panel-header">
    <p-checkbox
      *ngIf="showCheckbox"
      [binary]="true"
      [ngModel]="selected"
      [ariaLabel]="ariaLabel"
      [ariaLabelledBy]="ariaLabelledBy"
      (ngModelChange)="selectedChange.emit($event)"
      (onChange)="cancelClick($event.originalEvent)"
    ></p-checkbox>
    <div class="flex-grow-1">
      <ng-container *ngTemplateOutlet="header"></ng-container>
    </div>
    <span class="ml-1 en-expanding-panel-icon p-button-icon-right pi pi-chevron-down" *ngIf="expandable"></span>
  </div>
  <div class="en-expanding-panel-content" (click)="cancelClick($event)" #contentContainer>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</button>
