<div class="wrapper">
  <header>
    <h1 class="h1-lg text-center" translate>enrollment.YourInformation</h1>
    <div class="w-full text-center" *ngIf="informationFound">
      <p translate>enrollment.YourInformationFound</p>
      <p class="mt-3"><span translate>enrollment.WrongInformation</span>&nbsp;<a href="javascript:void(0);" (click)="clearInformation($event)" translate>enrollment.ClearInformation</a></p>
    </div>
  </header>

  <div class="w-full">
    <h2 translate>shared.Name</h2>
    <input type="text" pInputText id="enrollmentFullName" class="w-full" [(ngModel)]="enrolleeName" [placeholder]="'shared.Optional' | translate" />
  </div>
  <div>
    <h2 translate>shared.Address</h2>
    <en-address-selector #address [countryId]="countryId" (onAddressSelected)="addressSelected($event)"></en-address-selector>
  </div>

  <div class="two-buttons-container">
    <button pButton class="p-button-outlined" [label]="'shared.Back' | translate" (click)="navigateBack($event)"></button>
    <div class="spacer"></div>
    <button pButton id="enrollmentNext" [label]="'shared.Next' | translate" [disabled]="selectedAddress === null" (click)="navigateForward($event)"></button>
  </div>
  <div class="mb-4 w-full text-center" *ngIf="!informationFound">
    <a href="javascript:void(0);" (click)="clearInformation($event)" translate>enrollment.ClearInformation</a>
  </div>
</div>
