import { NgModule } from "@angular/core";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { SharedModule } from "@shared-sub/shared.module";
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { EnPhoneInputComponent } from "./en-phone-input.component";

@NgModule({
  imports: [BiTranslatePipe, BiTranslateDirective, SharedModule, InputTextModule, DropdownModule],
  declarations: [EnPhoneInputComponent],
  exports: [EnPhoneInputComponent]
})
export class EnPhoneInputModule {}
