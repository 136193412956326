<div class="en-overlay"></div>
<div class="en-dialog-frame zoom-zero">
  <header class="flex justify-content-center align-items-center relative w-full">
    <button class="p-button p-button-text p-button-rounded en-modal-button en-modal-back-button inline-flex sm:hidden p-1" (click)="back()">
      <span class="p-button-icon pi pi-chevron-left pi-chevron-left--back-button"></span>
    </button>

    <img class="main-logo sm:hidden h-3rem w-3rem" src="StaticFiles/images/logo/sms-service-icon.svg" alt="SMS-Service Logo" />

    <button class="p-button p-button-text p-button-rounded close-icon-button hidden sm:inline-flex p-1" (click)="close()">
      <span class="p-button-icon pi pi-times"></span>
    </button>
  </header>
  <div #scrollableArea class="en-dialog-frame-content pb-4 px-4 md:px-6">
    <ng-content></ng-content>
  </div>
</div>
