import { NgModule } from "@angular/core";
import { BiCountryToIconSrcPipe } from "./bi-country-to-icon.pipe";
import { BiHighlightSpacesPipe } from "./bi-highlight-spaces-pipe";
import { BiYearMonthPipe } from "./bi-year-month.pipe";

const pipes = [BiCountryToIconSrcPipe, BiYearMonthPipe, BiHighlightSpacesPipe];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class GlobalPipesModule {}
