<div class="wrapper text-center">
  <h2 translate>enrollment.SendATipTitle</h2>

  <p class="pb-2" translate>enrollment.SendATipSubtitle</p>

  <textarea pInputTextarea class="w-full" [(ngModel)]="tipText" name="tipText" rows="10"></textarea>

  <div class="two-buttons-container">
    <button pButton [label]="'shared.Cancel' | translate" class="p-button-outlined" (click)="onCancelClicked()"></button>
    <div class="spacer"></div>
    <button pButton [label]="'enrollment.SendTip' | translate" (click)="onSendClicked()"></button>
  </div>
</div>
