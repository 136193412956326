import { Pipe, PipeTransform } from "@angular/core";
import { BiPhoneFormatTool } from "@globals/classes/BiPhoneFormatTool";
import { isStringNullOrEmpty } from "@globals/helper-functions";

/**
 * Pipe for formatting a phone number with a country code prefix. A leading "+" or "00" is required!
 */
@Pipe({ name: "phoneFormatWithCode", standalone: true })
export class BiPhoneFormatWithCodePipe implements PipeTransform {
  transform(value: string): string {
    return !isStringNullOrEmpty(value) ? BiPhoneFormatTool.formatPhoneNumber(value) : "";
  }
}
