import { ElementRef } from "@angular/core";

export function isEnrollmentStep(obj: any): obj is EnrollmentStep {
  return obj !== null && typeof obj.onAbortClicked === "function" && typeof obj.onBackClicked === "function";
}

export interface EnrollmentStep {
  onAbortClicked(): boolean;
  onBackClicked(): boolean;
  setScrollingArea(area: ElementRef<HTMLDivElement>);
}
