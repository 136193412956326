<div class="wrapper text-center" *ngIf="!showTermsAndConditions">
  <header>
    <h1 class="h1-lg" translate>enrollment.SelectSenders</h1>
    <p translate>enrollment.Step3SubTitle</p>
  </header>

  <div class="mt-3">
    <div class="text-left w-full">
      <en-address class="inline-block w-full" [address]="selectedAddress">
        <ng-template #actions>
          <button pButton class="p-button-link p-button-dark-text" [label]="'enrollment.ChangeAddress' | translate" (click)="goToStep2()"></button>
        </ng-template>
      </en-address>
    </div>

    <section class="text-left mt-4">
      <h2 *ngIf="senders && senders.length > 0" translate>enrollment.SendersSuggestion</h2>

      <en-spinner *ngIf="!senders"></en-spinner>

      <en-sender *ngFor="let sender of senders" class="block mb-3" [sender]="sender" [selectable]="true" [(selected)]="sender.selected">
        <ng-template #footer>
          <div class="mt-4">
            <p class="mt-2" *ngIf="!sender.customerId" translate>enrollment.MySenders.NotCustomerYet</p>
          </div>
        </ng-template>
      </en-sender>
    </section>

    <section class="mt-6">
      <h2 id="searchTitle" class="text-left" translate>enrollment.SearchMoreSenders</h2>
      <en-sender-search
        [countryId]="countryId"
        [kvhx]="currentEnrollmentState.address.kvhx"
        [existingSenders]="senders"
        [title]="'enrollment.SearchMoreSenders' | translate"
        ariaLabelledBy="searchTitle"
        (onSenderSelected)="addNewSender($event)"
        (onSendATipClicked)="onSendATipClicked()"
      ></en-sender-search>
    </section>

    <div class="py-4">
      <p-checkbox id="enrollmentAcceptTermsAndConditions" [binary]="true" [(ngModel)]="termsAndConditionsAccepted" [required]="true" ariaLabelledBy="termsAndConditionsAcceptedCheckbox"></p-checkbox>
      <span class="inline-block ml-1" (click)="displayTermsAndConditions()">
        <a id="termsAndConditionsAcceptedCheckbox" class="cursor-pointer underline" translate>enrollment.TermsAndConditionsAccepted</a>
      </span>
    </div>
  </div>

  <div class="two-buttons-container">
    <button pButton class="p-button-outlined back-button" [label]="'shared.Back' | translate" (click)="goToStep2()"></button>
    <div class="spacer"></div>

    <button id="enrollmentSubscribe" pButton [label]="'shared.SubscribeShort' | translate" [disabled]="working || !termsAndConditionsAccepted || !senders" (click)="onSubscribeClicked()"></button>
  </div>
</div>

<en-spinner *ngIf="working" class="text-center" [centerInParent]="true" [hasBackground]="true"></en-spinner>

<div class="w-full" *ngIf="showTermsAndConditions" [@fadeIn]>
  <en-terms-and-conditions-main [showAsDialog]="false"></en-terms-and-conditions-main>
  <div class="two-buttons-container mt-5">
    <button pButton [label]="'shared.Accept' | translate" (click)="showTermsAndConditions = false; termsAndConditionsAccepted = true"></button>
  </div>
</div>
