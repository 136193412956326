import { trigger, state, animate, query, style, transition } from "@angular/animations";

export class FadeAnimations {
  /**
   * Animation for fading in/out an element being displayed "block".
   * Usage: add [@fadeInOutBlock]="{value: expression, params: {speed: speed, opacity: 1} }" to an element. "expression" must be a something resolving to
   * string toggling between "in" and "out", and "speed" is ms. "opacity" param is opacity after fade in (default 1).
   */
  public static fadeInOutBlockElement = trigger("fadeInOutBlock", [
    state(
      "in",
      style({
        height: "*",
        opacity: "{{ opacity }}"
      }),
      { params: { opacity: 1 } }
    ),
    state(
      "out",
      style({
        height: "0",
        margin: "0",
        maxHeight: "0",
        overflow: "hidden",
        opacity: "0"
      })
    ),

    transition("out <=> in", animate("{{ speed }}ms ease-in-out"), {
      params: { speed: 400 }
    })
  ]);

  /**
   * Animation for fading in/out an element being displayed "inline-block". 2 optional params can be provided: 1) opacity after element is faded in and
   * 2) the animation speed in ms. Default values are 1 and 400ms, respectively.
   * Usage example: <div [@fadeInOutInlineBlock]="{value: somePropertyOrExpression, params: {opacity: .75, speed: 500}}">I'm being animated!</div>
   * "somePropertyOrExpression" must be a something resolving to a string toggling between "in" and "out. "
   */
  public static fadeInOutInlineBlock = trigger("fadeInOutInlineBlock", [
    state(
      "in",
      style({
        display: "inline-block",
        opacity: "{{ opacity }}"
      }),
      { params: { opacity: 1 } }
    ),
    state(
      "out",
      style({
        display: "none",
        opacity: "0"
      })
    ),
    transition("out <=> in", animate("{{speed}}ms ease-in-out"), {
      params: { speed: 400 }
    })
  ]);

  /**
* Fade out/in animation used for animating the enter/leave state of element. Will fade in/out element by adjusting opacity and height.
* Usage example (you don't need an ngFor - you could as well just add it on some single element):
<div>
      <div *ngFor="let item of items" [@fadeInOut]>
          {{ item.name }}
      </div>
</div>

* Now each of the list items will become an animation when entering/leaving the view. Passing the length of the ngFor items array to the animation expression ensures that
* the animation is run each time this length changes.
*/
  public static fadeInOut = trigger("fadeInOut", [
    transition(":enter", [style({ opacity: "0" }), animate("{{speed}}ms ease-out", style({ opacity: "1" }))], {
      params: { speed: 400 }
    }),
    transition(":leave", [style({ opacity: "1" }), animate("{{speed}}ms ease-out", style({ opacity: "0" }))], {
      params: { speed: 400 }
    })
  ]);

  public static fadeOut = trigger("fadeOut", [
    state("staying", style({ opacity: "1" })),
    state("leaving", style({ opacity: "0" })),
    state("void", style({ opacity: "0" })),
    transition("* => leaving", animate(300))
  ]);

  /**
   * Animation used for only fading IN an element and not out.
   */
  public static fadeIn = trigger("fadeIn", [transition(":enter", [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))])]);

  /**
   * Fade out/in animation used for the entering/leaving of elements - typically in ngFor and ngIf. Will fade in/out elements by adjusting opacity and height.
   * This must be used on a container which CHILDREN should be animated.
   * Usage example:
   * <div [@fadeInOutChildren]="listItems.length">
   *      <div *ngFor="let item of items">
   *          {{ item }}
   *      </div>
   * </div>
   *
   * Now each of the list items will become an animation when entering/leaving the view. Passing the length of the ngFor items array to the animation expression ensures that
   * the animation is run each time this length changes.
   */
  public static fadeInOutChildren = trigger("fadeInOutChildren", [
    transition("* <=> *", [
      // animate elements entering the view
      query(":enter", [style({ opacity: 0 }), animate("300ms ease-in", style({ opacity: 1 }))], { optional: true }),

      // animate elements leaving the view
      query(":leave", [style({ opacity: 1 }), animate("300ms ease-out", style({ opacity: 0 }))], { optional: true })
    ])
  ]);

  /**
   * For adding fade in animation with small slide from top. Added to entering and leaving child elements of a container. Typically for elements using *ngIf or *ngFor directives
   * Usage example:
   * <div *ngIf="somePropertyOrWhatEver" [@fadeInDown]>
   *      <p>I'm being animated when I become visible </p>
   * </div>
   */
  public static fadeInDown = trigger("fadeInDown", [
    transition(":enter", [style({ opacity: "0", transform: "translateY(-10px)" }), animate(".3s ease-in", style({ opacity: "1", transform: "translateY(0px)" }))]),
    transition(":leave", [style({ opacity: "1", transform: "translateY(0px)" }), animate(".3s ease-out", style({ opacity: "0", transform: "translateY(-10px)" }))])
  ]);
}
