<en-expanding-panel [showCheckbox]="selectable" [(selected)]="sender.selected" [class.en-unenrolled]="!enrolled" [ariaLabel]="sender.name" (selectedChange)="clickSender($event)">
  <ng-template #header>
    <div class="en-sender-header-text w-12 relative" [attr.senderName]="sender.name">
      {{ sender.name }}
      <span *ngIf="sender.notRelevant" class="pi pi-exclamation-circle notice-icon-header text-orange-400"></span>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="en-sender-content" [class.desktop-padding]="selectable">
      <div class="mt-1 mb-3" (click)="$event.stopPropagation()">
        <a [href]="sender.url | link" target="_blank">{{ sender.url }}</a>
      </div>

      <div *ngIf="sender.companyRegistrationId">
        <span translate>shared.CompanyRegistrationId</span>:
        {{ sender.companyRegistrationId }}
      </div>

      <div class="mb-3"><span translate>shared.Address</span>: {{ sender.displayAddress }}</div>

      <h4 class="font-bold" translate>enrollment.SenderDescriptionHeader</h4>

      <p class="mb-2" [innerHtml]="sender.description | toBrTags"></p>

      <ng-container *ngIf="sender.notRelevant">
        <h4 class="font-bold mb-1" translate>
          shared.Notice
          <!-- Exclamation mark icon for mobile (as there not enough space to display it where the other one is) -->
          <span class="pi pi-exclamation-circle text-orange-400 notice-icon-mobile sm:hidden"></span>
        </h4>
        <div class="relative">
          <span class="pi pi-exclamation-circle text-orange-400 notice-icon-small vertical-center-absolute sm:display-inline-block"></span>
          <p translate>enrollment.SenderNotRelevant</p>
        </div>
      </ng-container>
    </div>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-template>
</en-expanding-panel>
