<form role="search" autocomplete="off" *ngIf="addressForm" [formGroup]="addressForm">
  <div class="address-selector grid">
    <div class="address-selector-field col-4">
      <span class="p-float-label">
        <p-inputMask
          id="zipCode"
          styleClass="w-12"
          [mask]="zipcodeRequiredLength === 4 ? '9999' : '99999'"
          slotChar=""
          formControlName="zipCode"
          [attr.aria-label]="'shared.Zipcode' | translate"
          [maxlength]="zipcodeRequiredLength"
        ></p-inputMask>
        <label for="zipCode"><span translate>shared.Zipcode</span></label>
      </span>
      <span class="field-error" *ngIf="zipCodeControl.errors && zipCodeControl.errors.unknownZipCode" translate> errorMessages.InvalidZip </span>
    </div>
    <div class="address-selector-field col-8">
      <span class="p-float-label">
        <input id="city" formControlName="city" type="text" autocomplete="city" pInputText class="inputfield w-full" />
        <label for="city" translate>shared.City</label>
      </span>
    </div>
    <div class="address-selector-field col-12">
      <span class="p-float-label">
        <p-autoComplete
          #streetSearch
          [minLength]="1"
          id="street"
          formControlName="street"
          inputId="street"
          autocomplete="street"
          [virtualScroll]="false"
          [suggestions]="filteredStreets$ | async"
          field="streetName"
          [forceSelection]="true"
          [delay]="500"
          [scrollHeight]="streetSearch.suggestions?.length > 3 ? '200px' : '150px'"
          styleClass="w-full"
          inputStyleClass="inputfield w-full"
          (onSelect)="streetSelected($event.value)"
          (completeMethod)="filterStreets($event.query)"
          (onClear)="streetCleared()"
          (onShow)="scrollOneDown($event)"
        >
        </p-autoComplete>
        <label for="street" translate>shared.Street</label>
      </span>
    </div>
    <div class="address-selector-field col-12">
      <span class="p-float-label">
        <p-autoComplete
          #houseNumberSearch
          formControlName="houseNumber"
          inputId="houseNumber"
          id="houseNumber"
          autocomplete="houseNumber"
          [suggestions]="suggestionsHouseNumber$ | async"
          [forceSelection]="true"
          field="houseNumberDisplayName"
          (completeMethod)="autoCompleteHouseNumberSearch($event.query)"
          (onSelect)="houseNumberSelected($event.value)"
          styleClass="w-full"
          inputStyleClass="inputfield w-full"
          (onClear)="houseNumberCleared()"
          (onShow)="scrollOneDown($event)"
        >
        </p-autoComplete>
        <label for="houseNumber" translate>shared.HouseNumber</label>
      </span>
    </div>
  </div>
</form>
