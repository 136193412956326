import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "link" })
export class EnLinkPipe implements PipeTransform {
  transform(value: string) {
    if (value) {
      if (/^https?:\/\//.exec(value)) return value;

      return "https://" + value;
    }

    return "#";
  }
}
