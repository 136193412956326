import { NgOptimizedImage } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@core-sub/core.module";
import { AppCanActivateGuard } from "@core-sub/routing/guards/en-app-can-activate.guard";
import { FeaturesModule } from "@features-sub/features.module";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { biTranslateProviders } from "@globals/bi-translate/bi-translate-providers";
import { SharedModule } from "@shared-sub/shared.module";
import { DropdownModule } from "primeng/dropdown";
import { SidebarModule } from "primeng/sidebar";
import { EnToolbarComponent } from "./en-toolbar/en-toolbar.component";
import { SubscriptionAppComponent } from "./subscription-app.component";

@NgModule({
  declarations: [SubscriptionAppComponent, EnToolbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot([]),
    CoreModule,
    SharedModule,
    FeaturesModule,
    SidebarModule,
    DropdownModule,
    NgOptimizedImage,
    BiTranslateDirective,
    BiTranslatePipe
  ],
  providers: [...biTranslateProviders, AppCanActivateGuard],
  bootstrap: [SubscriptionAppComponent]
})
export class AppModule {}
