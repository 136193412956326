<div class="en-address w-full">
  <div class="en-address__icon"><span class="pi pi-home"></span></div>
  <div class="w-full flex align-items-center">
    <div class="en-address__header">
      <div *ngIf="address.locationName !== ''" class="en-address__name">
        {{ address.locationName }}
      </div>
      <div class="en-address__display">{{ address | addressFormat }}</div>
    </div>
    <div class="en-address__actions" *ngIf="actions">
      <ng-container *ngTemplateOutlet="actions"></ng-container>
    </div>
  </div>
</div>
