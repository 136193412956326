import { AbstractControl, ValidatorFn } from "@angular/forms";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { isStringNullOrEmpty } from "@globals/helper-functions";
import { landLineLength_EN, landLineLength_FI, landLineLength_SE, phoneLength_DE, phoneLength_DK, phoneLength_EN, phoneLength_FI, phoneLength_NO, phoneLength_SE } from "./global-constants";

export class CustomValidators {
  /**
   * Validates a phone number's length. Sometimes, there's a min. length and a max. length and other times, there's 1 required length.
   * If length is below min., the error "phoneMin" is added to FormControl.
   * If length is more than max., the error "phoneMax" is added to FormControl.
   * @param isLandLine Whether the phone number to be validated is a landline numnber (fastnet)
   */
  public static phoneLengthValidator(countryId: BiCountryId, isLandLine = false): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value) {
        const inputLength = (c.value as number).toString().length;

        let minLength: number;
        let maxLength: number;

        if (isLandLine) {
          switch (countryId) {
            case BiCountryId.DK:
              minLength = phoneLength_DK;
              maxLength = phoneLength_DK;
              break;
            case BiCountryId.SE:
              minLength = landLineLength_SE[0];
              maxLength = landLineLength_SE[1];
              break;
            case BiCountryId.EN:
              minLength = landLineLength_EN[1];
              maxLength = landLineLength_EN[0];
              break;
            case BiCountryId.FI:
              minLength = landLineLength_FI[0];
              maxLength = landLineLength_FI[1];
              break;
            case BiCountryId.NO:
              minLength = phoneLength_NO;
              maxLength = phoneLength_NO;
              break;
            case BiCountryId.DE:
              minLength = phoneLength_DE[0];
              maxLength = phoneLength_DE[1];
              break;
          }
        } else {
          switch (countryId) {
            case BiCountryId.DK:
              minLength = phoneLength_DK;
              maxLength = phoneLength_DK;
              break;
            case BiCountryId.SE:
              minLength = phoneLength_SE[0];
              maxLength = phoneLength_SE[1];
              break;
            case BiCountryId.EN:
              minLength = phoneLength_EN;
              maxLength = phoneLength_EN;
              break;
            case BiCountryId.FI:
              minLength = phoneLength_FI[0];
              maxLength = phoneLength_FI[1];
              break;
            case BiCountryId.NO:
              minLength = phoneLength_NO;
              maxLength = phoneLength_NO;
              break;
            case BiCountryId.DE:
              minLength = phoneLength_DE[0];
              maxLength = phoneLength_DE[1];
              break;
          }
        }
        if (inputLength < minLength) return { phoneMin: true };
        if (inputLength > maxLength) return { phoneMax: true };
      }
      return null;
    };
  }

  /**
   * Validates that input value is only digits. This is usefull when input is for a string value that must only contain
   *  digits.
   * This will add the error "digitsOnly" to formcontrol if there's an error
   * @param whiteSpaceAllowed Flag telling if white spaces are allowed in the control's value. Validation function will just temporarily
   * remove white space and then validate
   */
  public static digitsOnly(whiteSpaceAllowed?: boolean): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (isStringNullOrEmpty(c.value)) return null; // no email to validate so no error

      const value = !whiteSpaceAllowed ? c.value : c.value.toString().replace(/\s/g, "");

      if (/^\d+$/.test(value)) return null; // No error

      return { digitsOnly: true }; // error!
    };
  }

  /**
   * Validates the max length is not exceeded with html tags excluded.
   */
  public static maxLengthWithoutHtml(maxLenght: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (isStringNullOrEmpty(c.value)) return null;

      const regex = new RegExp(/(<([^>]+)>)/gi);

      if (c.value.replace(regex, "").length > maxLenght) {
        return { maxlength: true };
      }

      return null;
    };
  }

  /**
   * Validator checking wether a value has a required character length. This also works for numbers!
   *  If validation fails, this will add the error "length" to the errors object of the form control
   */
  public static checkLength(requiredLength: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value) {
        if (!isNaN(c.value) && c.value.toString().length !== requiredLength) return { length: true };
        else if (typeof c.value === "string" && c.value.length !== requiredLength) return { length: true };
      }
      return null;
    };
  }

  /**
   * Validator that checks whether the control has a value or at least 1 of all the passed controls has.
   * If validation fails, the error "atLeast1Required" is set on all controls
   * NB: this validator ensures that if there no more errors on a control that earlier had, then errors object is set to null and control is updated.
   * @param theOtherControls The other form controls that this control depends on
   * @returns null if no errors, {atLeast1Required: true} if error
   */
  public static checkAtLeastOneHasValue(...theOtherControls: AbstractControl[]): ValidatorFn {
    // Local function used for checking if there are any errors on the other controls and then remove the "required" error
    const checkAndRemoveErrorOnOtherCtrl = () => {
      theOtherControls.forEach(control => {
        if (control.errors) {
          delete control.errors.atLeast1Required;
          // If no more error, set errors object to null and update the control
          if (Object.keys(control.errors).length === 0) {
            control.setErrors(null);
            control.markAsUntouched();
            control.updateValueAndValidity();
          }
        }
      });
    };

    return (c: AbstractControl): { [key: string]: boolean } | null => {
      // if (c.pristine && theOtherControls.findIndex((c) => c.dirty) === -1) return null;

      if (c.value) {
        // This validation is succesfull - be sure to fully remove this error on the other controls
        checkAndRemoveErrorOnOtherCtrl();
        return null;
      } else {
        // check the other controls
        for (let i = 0; i < theOtherControls.length; i++) {
          if (theOtherControls[i].value) {
            checkAndRemoveErrorOnOtherCtrl();
            return null;
          }
        }
      }

      // No values! For all control, set errors and mark as dirty and touched (for the view to show the errors)
      theOtherControls.forEach(control => {
        if (control.touched || control.dirty) {
          control.setErrors({ atLeast1Required: true });
          control.markAsDirty();
          control.markAsTouched();
        }
      });
      return { atLeast1Required: true };
    };
  }

  /**
   * Function for validating a username or email entered in a formcontrol. Uses same email regular expr. as Angular source code.
   *  If validation fails, this will add the error "email" to the errors object of the form control.
   * NB: No/empty value means no error (nothing to validate).      *
   */
  public static email(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (!c.value || c.value === "") return null; // no email to validate so no error

      const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

      if (emailRegEx.test(c.value) && !/\s/.test(c.value)) {
        return null;
      } // No error

      return { email: true }; // error!
    };
  }

  /**
   * Validates that the value is a valid URL (only looking at format). If validation fails, adds the error "url" to the errors.
   */
  public static isUrl(): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | undefined => {
      if (isStringNullOrEmpty(c.value)) return undefined;
      const regex = new RegExp(/^(?:https?:\/\/)[\u00BF-\u1FFF\u2C00-\uD7FF-a-zA-Z0-9._+]{1,256}\.[\u00BF-\u1FFF\u2C00-\uD7FF-a-zA-Z0-9]{1,6}\b(?:\/\S*)?$/);
      return !regex.test(c.value) ? { url: true } : undefined;
    };
  }

  /**
   * Validator that checks if at least one of the controls has a value.
   * If validation fails, it adds the error "minimumOneFilled" to the errors object.
   * @param controls The form controls to validate
   * @returns A validator function
   */
  static minimumOneFilledValidator(...controls: AbstractControl[]): ValidatorFn {
    return (): { [key: string]: any } | null => {
      let filledCount = 0;

      controls.forEach(ctrl => {
        if (ctrl.value) {
          filledCount++;
        }
      });

      if (filledCount === 0) {
        return { minimumOneFilled: true };
      }

      return null;
    };
  }
}
