<div id="en-language-selector" class="flex flex-column align-items-center">
  <div class="cursor-pointer flex flex-row flex-nowrap align-items-center" (click)="isOpen = !isOpen">
    <img class="rotate" [attr.src]="selectedLanguage.iconSrc" [attr.alt]="selectedLanguage.translationKey | translate" />
    <span class="language-name-text inline-block">{{ selectedLanguage.translationKey | translate }}</span>
    <span class="pi pi-chevron-down cursor-pointer" [class.rotate-180]="isOpen"></span>
  </div>
  <div *ngIf="isOpen" class="languages-list-box" [@fadeInDown]>
    <table class="mx-auto text-left">
      <tr *ngFor="let languageItem of selectableLanguages">
        <td>
          <button class="p-component p-button p-button-text inline-block selector-button w-full flex flex-row flex-nowrap justify-items-start" (click)="languageSelected(languageItem)">
            <img [attr.src]="languageItem.iconSrc" [attr.alt]="languageItem.translationKey | translate" class="mr-2" />
            <span class="p-button-label text-left">{{ languageItem.translationKey | translate }}</span>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>
