import { ChangeDetectorRef } from "@angular/core";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { cloneObject } from "@globals/helper-functions";
import { SenderExt } from "@models-sub/ext/SenderExt";
import { sortBy } from "lodash";
import { DialogService } from "primeng/dynamicdialog";
import { Observable, switchMap, tap } from "rxjs";
import { SendATipDialogContentComponent } from "./../../../features-shared/send-a-tip-dialog-content/send-a-tip-dialog-content.component";
import { EnrollmentAddressDto } from "@globals/openapi-models/model/enrollmentAddressDto";

export abstract class SelectSendersBase {
  public selectedAddress: EnrollmentAddressDto;
  public senders: Array<SenderExt>;
  public relevantSenders: Array<SenderExt>;
  public working: boolean;

  protected preselectSenders: boolean;
  public hasSelectedSenders = false;

  constructor(
    protected enrollmentService: EnrollmentService,
    protected authService: EnrollmentAuthenticationService,
    protected cd: ChangeDetectorRef,
    protected dialogService: DialogService
  ) {}

  /**
   * Call this inside ngOnInit in inheriting class!
   */
  public onInit() {
    this.getSelectedAddress()
      .pipe(
        tap(a => (this.selectedAddress = a)),
        switchMap(a => this.enrollmentService.getRelevantSenders(a.countryId, a.municipalityCode, a.kvhx, this.authService.sender?.id))
      )
      .subscribe(senders => {
        //if we got a sender in store, make sure to put it to the top of lhe list
        const sender = this.authService.sender;
        if (sender) {
          const indexOfExistingSender = senders.findIndex(s => s.id === sender.id);
          if (indexOfExistingSender !== -1) {
            senders.unshift(...senders.splice(indexOfExistingSender, 1));
          }
        }
        this.relevantSenders = cloneObject(senders);

        this.senders = senders.slice(0, Math.min(senders.length, 5));

        if (this.preselectSenders) {
          this.senders.forEach(s => (s.selected = true)); // select them all default
          this.hasSelectedSenders = this.senders.length > 0;
        }
        this.cd.detectChanges();
      });
  }

  public addNewSender(sender: SenderExt) {
    sender.selected = true;

    if (sender.customerId) {
      const indexOfRelevantSender = this.relevantSenders.findIndex(s => s.id === sender.id);
      if (indexOfRelevantSender === -1) {
        sender.notRelevant = true;
      }
    } else {
      if (this.selectedAddress.kvhx.substring(1, 4) !== sender.kvhx.substring(1, 4)) {
        sender.notRelevant = true;
      }
    }
    this.senders = sortBy([...this.senders, sender], s => s.name);
    this.checkForSelectedSenders();
  }

  public checkForSelectedSenders() {
    if (!this.senders) return false;
    this.hasSelectedSenders = this.senders.some(s => s.selected === true);
  }

  public onSendATipClicked() {
    this.dialogService.open(SendATipDialogContentComponent, {
      data: { address: this.selectedAddress },
      closable: true,
      closeOnEscape: true,
      showHeader: false
    });
  }

  /**
   * Should return the relevant address for which senders are being selected/added
   */
  protected abstract getSelectedAddress(): Observable<EnrollmentAddressDto>;

  protected abstract onFinish(): void;
}
