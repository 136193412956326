/**
 * Class for all possible web app routes.
 * We try to follow Google's guideline for url naming conventions: https://support.google.com/webmasters/answer/76329?hl=en
 */
export class RouteNamesEn {
  public static readonly login = "login";
  public static readonly aboutPage = "about";
  public static readonly privacyPolicy = "privacy-policy";
  public static readonly termsAndConditions = "terms-and-conditions";
  public static readonly mySenders = "my-senders";
  public static readonly unsubscribeSender = "unsubscribe-sender";

  public static enrollmentStepsRoutes = {
    main: "enrollment",
    step1: "step-1",
    step2: "step-2",
    step3: "step-3"
  };

  private static pageRoutes = [
    RouteNamesEn.aboutPage,
    RouteNamesEn.privacyPolicy,
    RouteNamesEn.login,
    RouteNamesEn.mySenders,
    RouteNamesEn.termsAndConditions,
    RouteNamesEn.enrollmentStepsRoutes.main
  ];

  public static isRouteInUse(path: string) {
    return this.pageRoutes.some(p => path.indexOf(p) > -1);
  }
}
