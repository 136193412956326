import { HttpClient } from "@angular/common/http";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";

/**
 * Custom translations loader that gets the translations from backend.
 */
export class BiHttpTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    let languageId = BiCountryId.DK;
    if (lang === "se") languageId = BiCountryId.SE;
    if (lang === "en") languageId = BiCountryId.EN;
    if (lang === "fi") languageId = BiCountryId.FI;
    if (lang === "no") languageId = BiCountryId.NO;

    return this.http.get("/api/Common/GetResourcesJson", {
      params: { languageId }
    });
  }
}
