@if (showIFrameMessage) {
  <div class="iframe-block-message">
    <p translate>enrollment.IFrameBlockedMessage</p>
  </div>
} @else {
  <en-toolbar
    *ngIf="showToolbar$ | async"
    [loggedIn]="loggedIn"
    (onChangeLanguage)="onChangeLanguage($event)"
    (onShowPrivacyPolicy)="showPrivacyPolicy()"
    (onLogin)="onLogin()"
    (onLogout)="onLogout()"
    (onMainLogoClicked)="onMainLogoClicked()"
  />

  <main class="content" [@appRouterTransition]="getAppState(o)" *ngIf="!isInitializing">
    <router-outlet #o="outlet"></router-outlet>
  </main>
}
