import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild, computed, input } from "@angular/core";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { BiCountryId, BiLanguageId, getCountryByLanguageCode } from "@globals/enums/BiLanguageAndCountryId";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { EnLanguageSelectorComponent } from "@shared-sub/components/en-language-selector/en-language-selector.component";
import { map } from "rxjs";
import { getLanguageCodeByHostName } from "../../main";

@UntilDestroy()
@Component({
  selector: "en-toolbar",
  templateUrl: "./en-toolbar.component.html",
  styleUrls: ["./en-toolbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnToolbarComponent implements AfterViewInit {
  @Output() onLogin = new EventEmitter();
  @Output() onLogout = new EventEmitter();
  @Output() onChangeLanguage = new EventEmitter<BiLanguageId>();
  @Output() onShowPrivacyPolicy = new EventEmitter();
  @Output() onMainLogoClicked = new EventEmitter();
  @ViewChild("languageSelectorDesktop")
  languageSelectorDesktop: EnLanguageSelectorComponent;
  @ViewChild("languageSelectorMobile")
  languageSelectorMobile: EnLanguageSelectorComponent;

  public loggedIn = input.required<boolean>();
  public showSideMenu: boolean;
  public loginButtonLabel = computed(() => (this.loggedIn() ? "app.Logout" : "shared.Login"));

  public RouteNames = RouteNamesEn;
  public logoUrl: string;

  constructor(private globalStateAndEventsServiceEn: GlobalStateAndEventsServiceEn) {
    this.initLogoUrl();
  }

  public ngAfterViewInit() {
    this.globalStateAndEventsServiceEn.state$
      .pipe(
        untilDestroyed(this),
        map(s => s.selectedLanguage)
      )
      .subscribe(language => {
        this.languageSelectorDesktop.setSelectedLanguage(language);
        this.languageSelectorMobile.setSelectedLanguage(language);
      });
  }

  public showPrivacyPolicy() {
    this.showSideMenu = false;
    this.onShowPrivacyPolicy.emit();
  }

  public loginOrOut() {
    this.showSideMenu = false;
    if (this.loggedIn()) this.onLogout.emit();
    else this.onLogin.emit();
  }

  private initLogoUrl() {
    const languageCode = getLanguageCodeByHostName();
    const countryId = getCountryByLanguageCode(languageCode);
    this.logoUrl = `StaticFiles/images/logo/with_icon/SMS-Service_${BiCountryId[countryId]}.svg`;
  }
}
