import cloneDeep from "lodash-es/cloneDeep";
import { SelectItem } from "primeng/api";

import { BiCountryId, BiLanguageId, LanguageCodes } from "./enums/BiLanguageAndCountryId";

/**
 * Generates a unique string id. Copied from: https://gist.github.com/gordonbrander/2230317
 */
export function uniqueID(): string {
  return "_" + Math.random().toString(36).substr(2, 9);
}

/**
 * Detects whether the app is currently running in Internet Explorer or not.
 * Found here: https://stackoverflow.com/a/9851769
 */
export function isRunningInIE() {
  return false || !!(document as any).documentMode;
}

/**
 * Helper for determining wether a string is null, undefined or blank
 * @param theString
 */
export function isStringNullOrEmpty(str: string | null | undefined): boolean {
  return !str || /^\s*$/.test(str);
}

/**
 * Compares 2 strings. Equal if string1 === string2 or if the lowercased version are equal. Checks for null
 * @param string1?
 * @param string2?
 */
export function areStringsEqual(string1?: string, string2?: string): boolean {
  if (string1 === string2) return true;
  if (string1 && string2) return string1.toLowerCase() === string2.toLowerCase();
  return false;
}

/**
 * Makes a full clone of an object. E.i. you get a ref. to a new, identical object
 */
export function cloneObject<T>(obj: T): T {
  return cloneDeep(obj);
}

/**
 * Replaces all occurrences of new lines in a string (e.g. from a textarea element )
 * with HTML '<br>' tags
 */
export function convertNewlinesToHtmlTags(stringWithLineBreaks: string) {
  return stringWithLineBreaks.replace(/(?:\r\n|\r|\n|\u21B5)/g, "<br/>");
}

/**
 * Takes a string containing HTML and strips it all out leaving only text left
 */
export function stripHtml(stringWithHtml: string) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = stringWithHtml;
  return tmp.textContent || tmp.innerText || "";
}

export function getLanguageCodeByLanguageId(languageId: BiLanguageId) {
  switch (languageId) {
    case BiLanguageId.DK:
      return "da";
    case BiLanguageId.SE:
      return "se";
    case BiLanguageId.EN:
      return "en";
    case BiLanguageId.FI:
      return "fi";
    case BiLanguageId.NO:
      return "no";
  }
}
export function getLanguageCodeByCountryId(countryId: BiCountryId) {
  switch (countryId) {
    case BiCountryId.DK:
      return "da";
    case BiCountryId.SE:
      return "se";
    case BiCountryId.EN:
      return "en";
    case BiCountryId.FI:
      return "fi";
    case BiCountryId.NO:
      return "no";
  }
}

export function getCountryCodeByCountryId(countryId: BiCountryId) {
  switch (countryId) {
    case BiCountryId.DK:
      return "45";
    case BiCountryId.SE:
      return "46";
    case BiCountryId.EN:
      return "44";
    case BiCountryId.FI:
      return "358";
    case BiCountryId.NO:
      return "47";
  }
}

export function getDateFormatByCountry(countryId: BiCountryId) {
  if (countryId === BiCountryId.DK) return "DD/MM/YYYY";
  if (countryId === BiCountryId.SE) return "YYYY-MM-DD";
  if (countryId === BiCountryId.EN) return "DD/MM/YYYY";
  if (countryId === BiCountryId.FI) return "DD/MM/YYYY";
  if (countryId === BiCountryId.NO) return "YYYY-MM-DD";
}

export function hasIndustryCodes(countryId: number) {
  if (countryId === BiCountryId.DK) return true;
  if (countryId === BiCountryId.SE) return false;
  if (countryId === BiCountryId.EN) return false;
  if (countryId === BiCountryId.FI) return true;
  if (countryId === BiCountryId.NO) return true;
}

/**
 * Returns language code by looking at hostname. If it cannot be determined, null is returned.
 * NOTE: the SMS-Service WEB app uses language code as prefix in the domain (like se.sms-service.dk)
 * and therefore, we check for "se.sms", "fi.sms" etc.
 * OBS: DO NOT USE IN ENROLLMENT APP!
 */
export function getLanguageCodeByHostName(): LanguageCodes {
  if (location.hostname.indexOf("se.sms") !== -1) return "se";
  if (location.hostname.indexOf("dk.sms") !== -1) return "da";
  if (location.hostname.indexOf("fi.sms") !== -1) return "fi";
  if (location.hostname.indexOf("no.sms") !== -1) return "no";

  return undefined;
}

/**
 * Returns a new array where the item at specified index is removed. Usefull when working with immutable arrays.
 */
export function removeItemAtImmutable<T>(array: Array<T>, index: number) {
  if (index < array.length) return array.slice(0, index).concat(array.slice(index + 1, array.length));
  else {
    const clone = cloneObject(array);
    clone.pop();
    return clone;
  }
}

/**
 * Checks if the application is running inside an iFrame or not.
 */
export function isInsideIFrameOrPopup() {
  return window !== window.parent;
}

/**
 * Generates a string representing an address. Used for consistency in the app so addresses are always displayed the same.
 * The generated string is build up depending on the passed address data and follows one of the format:
 * - STREET HOUSENUMBER LETTER, FLOOR. DOOR. - ZIP CITY
 * - STREET HOUSENUMBER-METERSLETTER - ZIP CITY
 * - STREET METERSLETTER - ZIP CITY
 */
export function createAddressString(countryId: BiCountryId, zipcode?: number, city?: string, street?: string, houseNr?: number, meters?: number, letter?: string, floor?: string, door?: string) {
  const addressParts = [];
  if (!isStringNullOrEmpty(street)) addressParts.push(street);
  if (houseNr && houseNr !== 0) {
    addressParts.push(" " + houseNr);
  }

  const hasLetter = !isStringNullOrEmpty(letter) && letter !== "0";
  const hasFloor = !isStringNullOrEmpty(floor) && floor !== "0";
  const hasDoor = !isStringNullOrEmpty(door) && door !== "0";

  if (meters != null) {
    addressParts.push("-" + meters);
  }

  if (hasLetter) addressParts.push(letter);

  if (hasFloor || hasDoor) {
    addressParts.push(", ");
    addressParts.push(hasFloor ? floor + ". " : "");
    addressParts.push(hasDoor ? door + ". " : "");
  }

  if (zipcode || !isStringNullOrEmpty(city)) {
    if (addressParts.length > 0) addressParts.push(", ");

    addressParts.push(zipcode ? formatZipCode(countryId, zipcode) + " " : "");
    addressParts.push(!isStringNullOrEmpty(city) ? city : "");
  }

  return addressParts.join("");
}

/**
 * Formats a zipcode based on country
 */
export function formatZipCode(countryId: number, zipCode: number) {
  if (countryId === +BiCountryId.SE || countryId === +BiCountryId.FI) return ("0000" + zipCode).substr(-5);
  if (countryId === +BiCountryId.NO) return ("0000" + zipCode).substr(-4);
  else return zipCode.toString();
}

/**
 * Generates an array of year selection items with a value and label.
 * @param maxYear The biggest year value
 * @param min The lowest year value. Default is 2009
 */
export function generateYearSelectItems(maxYear: number, min = 2009) {
  const max = new Date().getFullYear() + 1;

  const years: Array<SelectItem> = [];

  for (let i = max; i >= min; i--) {
    years.push({
      label: i.toString(),
      value: i
    });
  }
  return years;
}

/**
 * Sets value of the given property of a given object. Supports nested object properties.
 */
export function setPropertyValue<T>(obj: any, propertyName: string, valueToSet: T) {
  if (propertyName.indexOf(".") === -1) obj[propertyName] = valueToSet;
  else {
    const keys = propertyName.split(".");
    let objectWithKeyToSet = obj;

    for (let i = 0; i < keys.length; i++) {
      if (i === keys.length - 1) return (objectWithKeyToSet[keys[i]] = valueToSet);
      else objectWithKeyToSet = obj[keys[i]];
    }
  }
}
