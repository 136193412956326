import { Location } from "@angular/common";
import { AfterViewInit, Component, Input, Type, ViewChild } from "@angular/core";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { ComponentContainerDirective } from "@globals/directives/component-container.directive";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DisplayTextBaseComponent } from "@shared-sub/classes/BaseClasses/DisplayTextComponentBase";
import { filter, map, take } from "rxjs";
import { EnTermsAndConditionsDkComponent } from "./en-terms-and-conditions-dk.component";
import { EnTermsAndConditionsFiComponent } from "./en-terms-and-conditions-fi.component";
import { EnTermsAndConditionsSeComponent } from "./en-terms-and-conditions-se.component";

@UntilDestroy()
@Component({
  selector: "en-terms-and-conditions-main",
  template: `
    <en-dialog-frame *ngIf="showAsDialog; else nonDialogView" (onClose)="goBack()" (onBack)="goBack()">
      <div class="w-full">
        <h1 class="text-center pb-4" translate>app.TermsAndConditions</h1>
        <ng-template componentContainer></ng-template>
      </div>
    </en-dialog-frame>
    <ng-template #nonDialogView>
      <div class="w-full">
        <h1 class="text-center pb-4" translate>app.TermsAndConditions</h1>
        <ng-template componentContainer></ng-template>
      </div>
    </ng-template>
  `,
  styles: [":host{display: block;}"]
})
export class EnTermsAndConditionsMainComponent implements AfterViewInit {
  @ViewChild(ComponentContainerDirective)
  componentContainer!: ComponentContainerDirective;

  @Input() showAsDialog = true;

  constructor(private location: Location, private globalStateService: GlobalStateAndEventsServiceEn) {}

  public ngAfterViewInit() {
    this.globalStateService.state$
      .pipe(
        untilDestroyed(this),
        filter(s => s.countryId != null && s.selectedLanguage != null),
        take(1),
        map(s => {
          return { countryId: s.countryId, languageId: s.selectedLanguage };
        })
      )
      .subscribe(countryAndLang => {
        let component2Load: Type<DisplayTextBaseComponent> = EnTermsAndConditionsDkComponent;

        // First find out what component to load. First when the ngAfterViewInit has been called, we can access viewchild.
        // Dynamically insert the correct component depending on country
        if (countryAndLang.countryId === BiCountryId.SE) component2Load = EnTermsAndConditionsSeComponent;
        else if (countryAndLang.countryId === BiCountryId.FI) component2Load = EnTermsAndConditionsFiComponent;

        const componentRef = this.componentContainer.viewContainerRef.createComponent(component2Load);
        componentRef.instance.languageId = countryAndLang.languageId;
        this.componentContainer.cd.detectChanges(); // important! Otherwise, view won't update
      });
  }

  public goBack() {
    this.location.back();
  }
}
