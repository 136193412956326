/**
 * Object with the names of the keys used in window.sessionStorage
 */

export const WindowSessionStorageNamesEn = {
  enrollmentTokenModel: "enrollmentToken",
  enrollmentAddressAndName: "enrollmentAddressAndName",
  language: "language",
  sender: "sender"
};
