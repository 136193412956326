import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { HttpInterceptorEn } from "./HttpInterceptorEn";
import { EnErrorHandlerService } from "./utility-services/en-error-handler.service";

@NgModule({
  imports: [ToastrModule.forRoot()],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorEn,
      multi: true
    },
    { provide: ErrorHandler, useClass: EnErrorHandlerService }
  ]
})
export class CoreModule {}
