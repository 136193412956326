import { filter } from "rxjs";
import { Observable } from "rxjs/internal/Observable";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrolleeInfoTrackingService } from "@core-sub/utility-services/enrollee-info-tracking.service";
import { SenderExt } from "@models-sub/ext/SenderExt";
import { UntilDestroy } from "@ngneat/until-destroy";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { DialogService } from "primeng/dynamicdialog";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { map, take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "welcome-page",
  templateUrl: "./welcome-page.component.html",
  styleUrls: ["./welcome-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService]
})
export class WelcomePageComponent {
  public countryIds = BiCountryId;
  public countryId$: Observable<BiCountryId>;

  constructor(
    private globalStateService: GlobalStateAndEventsServiceEn,
    private router: Router,
    private authService: EnrollmentAuthenticationService,
    private domSanitizer: DomSanitizer,
    private trackingService: EnrolleeInfoTrackingService
  ) {
    // Only set the tracking info if user isn't' comming from a different page in this application (e.i. the user has already visitied page).
    // The "NavigationEnd" contains a url which is the previous route
    if (!this.trackingService.getTrackingInfo()) this.trackingService.setTrackingInfo(window.location.href, document.referrer);

    this.countryId$ = this.globalStateService.state$.pipe(
      filter(x => x?.countryId != undefined),
      map(x => x.countryId),
      take(1)
    );
  }

  public onLoginClicked() {
    this.router.navigate([RouteNamesEn.login]);
  }

  public startEnrollment() {
    this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main]);
  }
  public unsubscribeSender() {
    this.router.navigate([RouteNamesEn.unsubscribeSender]);
  }

  public get sender(): SenderExt {
    return this.authService.sender;
  }

  public get imageUrl(): SafeUrl {
    const image = this.authService.sender?.logo;
    if (image) return this.domSanitizer.bypassSecurityTrustUrl("data:image/jpg;base64, " + image);
  }
}
