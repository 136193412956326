import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild, ViewRef } from "@angular/core";
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator } from "@angular/forms";
import { EnLocalizationHelperService } from "@core-sub/utility-services/en-localization-helper.service";
import { BiTranslateService } from "@globals/bi-translate";
import { CustomValidators } from "@globals/classes/CustomValidators";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { isStringNullOrEmpty, uniqueID } from "@globals/helper-functions";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SelectItem } from "primeng/api";
import { debounceTime, distinctUntilChanged, filter, take } from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: "en-phone-input",
  templateUrl: "./en-phone-input.component.html",
  styleUrls: ["./en-phone-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnPhoneInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => EnPhoneInputComponent)
    }
  ]
})
export class EnPhoneInputComponent implements ControlValueAccessor, Validator, AfterViewInit, OnInit {
  private _countryId: BiCountryId = BiCountryId.DK;

  /**
   * Allow the input to be disabled, Default false
   */
  @Input() public disabled = false;

  public get countryId(): BiCountryId {
    return this._countryId;
  }

  @Input() public set countryId(value: BiCountryId) {
    this._countryId = value;
    this.selectedCountryCode = "+" + this.localizor.getLanguageConfigByCountry(this._countryId).phoneCountryCode.toString();
  }

  /**
   * Set true if you want the input to get autofocus on load
   */
  @Input() public autofocus = false;

  @Input() public label: string;
  @Input() public hasLabel = true;

  @Output() public onEnter = new EventEmitter();

  public selectedCountryCode: string;

  private _phonePrefix: string;
  public get phonePrefix() {
    return this._phonePrefix;
  }

  public inputValue: string;

  public phoneMinLength: number;
  public phoneMaxLength: number;

  /**
   * HTML input id
   */
  public inputId: string;

  public hasFocus: boolean;

  /**
   * Fired when value of the native text input  has changed
   */
  public propagateChange = (value: string) => {
    return;
  };

  // Function to call when the input is touched.
  public onTouched = () => {
    return;
  };

  /**
   * Reference to the form control created for this control value accessor component.
   * Makes it easy to access things like errors, status etc. for the control.
   */
  public controlRef: NgControl;

  public countryCodes: Array<SelectItem>;

  @ViewChild("input") private inputEl: ElementRef<HTMLInputElement>;

  constructor(
    private cd: ChangeDetectorRef,
    private localizor: EnLocalizationHelperService,
    private injector: Injector,
    private translator: BiTranslateService
  ) {}

  public ngOnInit() {
    this.inputId = uniqueID();

    this.initCountryCodes();

    this.setPhoneNumberValidation();

    if (this.hasLabel && isStringNullOrEmpty(this.label)) {
      // set default label
      this.label = this.translator.instant("shared.CountryCodeAndMobile");
      // We can't always assume that correct language is set, so listen to the language change event and set label accordingly
      this.translator.onLangChange.pipe(untilDestroyed(this), take(1)).subscribe(() => (this.label = this.translator.instant("shared.CountryCodeAndMobile")));
    }
  }

  ngAfterViewInit() {
    // Using the Injector, we can inject the formcontrol bound to this component
    const control = this.injector.get(NgControl, undefined);
    if (control) this.controlRef = control;

    // Make sure that if user enters non-numeric chars, then remove them (input type "tel" or "number" allows for entering "-.,+'")
    this.controlRef.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(val => val != null),
        debounceTime(200)
      )
      .subscribe((val: string) => {
        // Determine if new value is a number or not.
        const newVal = val.replace(/[^0-9]/g, "");
        if (newVal !== val) {
          this.writeValue(newVal);
          this.propagateChange(newVal);
        }
      });

    // As autofocus doesn't work on the native input, we set it here
    if (this.autofocus) this.inputEl.nativeElement.focus();
  }

  writeValue(val: string): void {
    this.inputValue = val;
    if (!(this.cd as ViewRef).destroyed) this.cd.detectChanges();
  }

  onChange(val: string) {
    this.propagateChange(val);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers on touch event. This is to be called when the input loses focus (blur)
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.detectChanges();
  }

  private setPhoneNumberValidation() {
    const languageConfig = this.localizor.getLanguageConfigByCountry(this._countryId);
    this.phoneMinLength = languageConfig.minimumPhoneLength;
    this.phoneMaxLength = languageConfig.maximumPhoneLength;
    this._phonePrefix = "+" + languageConfig.phoneCountryCode;
  }

  public onCountryCodeChanged() {
    this._countryId = this.localizor.getCountryIdByPhoneCode(this.selectedCountryCode);
    this.setPhoneNumberValidation();
    this.controlRef.control.markAsTouched();
    this.controlRef.control.updateValueAndValidity();
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    if (isStringNullOrEmpty(control.value)) return control.errors;
    return CustomValidators.phoneLengthValidator(this._countryId, false)(control);
  }

  /**
   * Get the value of the input including the selected phone coutry code. This is ONLY relevant if canSelectCountryCode === true
   */
  public getValueWithCode(): string {
    if (this.inputEl.nativeElement.value?.length > 0) {
      return this.selectedCountryCode + this.inputEl.nativeElement.value;
    }
    return undefined;
  }

  private initCountryCodes() {
    this.countryCodes = [
      { label: "+45", value: "+45" },
      { label: "+46", value: "+46" },
      { label: "+47", value: "+47" },
      { label: "+49", value: "+49" },
      { label: "+358", value: "+358" }
    ];
  }
}
