import { NgModule } from "@angular/core";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { BiPhoneFormatWithCodePipe } from "@globals/pipes/bi-phone-format-with-code.pipe";
import { EnPhoneInputModule } from "@shared-sub/components/en-phone-input/en-phone-input.module";
import { EnPipesModule } from "@shared-sub/pipes/en-pipes.module";
import { SharedModule } from "@shared-sub/shared.module";
import { MobileAndPinComponent } from "./mobile-and-pin.component";

@NgModule({
  declarations: [MobileAndPinComponent],
  exports: [MobileAndPinComponent],
  imports: [SharedModule, BiTranslatePipe, BiTranslateDirective, EnPhoneInputModule, EnPipesModule, BiPhoneFormatWithCodePipe]
})
export class MobileAndPinModule {}
