<div class="flex welcome-text-area">
  <div class="w-12 sm:w-10 welcome-middle-area">
    <header>
      <h1 class="h1-lg mb-3" translate>enrollment.FrontPageHeader</h1>
      <div *ngIf="sender">
        <h3 class="mx-auto" translate>enrollment.InCooperationWith</h3>
        <img *ngIf="imageUrl" [src]="imageUrl" alt="" />
        <h3 *ngIf="imageUrl" class="mx-auto">{{ sender.name }}</h3>
        <h1 *ngIf="!imageUrl" class="mx-auto">{{ sender.name }}</h1>
      </div>
      <h3 class="mx-auto" translate>enrollment.FrontPageSubHeader</h3>
    </header>
    <div class="info-box-blue mt-2">
      <table class="text-left mx-auto">
        <tr>
          <td><span class="pi pi-phone"></span></td>
          <td><span translate>enrollment.SubscribeWithMobile</span></td>
        </tr>
        <tr>
          <td><span class="pi pi-list"></span></td>
          <td><span translate>enrollment.SelectSendersToReceiveFrom</span></td>
        </tr>
        <tr>
          <td><span class="pi pi-comment"></span></td>
          <td>
            <span translate>enrollment.ReceiveRelevantSms</span>
          </td>
        </tr>
      </table>
    </div>

    <!-- Subscribe now button MOBILE-->
    <button pButton id="enrollmentCreateNewSubscriptionButton" class="mt-4 mb-3" [label]="'enrollment.SubscribeNow' | translate" (click)="startEnrollment()"></button>

    <p class="mt-3 text-sm font-medium" translate>
      enrollment.AlreadySubscribed
      <a id="enrollmentLogin" class="cursor-pointer underline" (click)="onLoginClicked()" translate>enrollment.LoginText</a>
    </p>
    <p class="mt-3 mb-5 text-sm font-medium" translate>
      enrollment.Unsubscribe1919
      <a class="cursor-pointer underline" (click)="unsubscribeSender()" translate>shared.ReadMoreHere</a>
    </p>
  </div>
</div>

<div class="hidden md:inline-block phone-images-area" *ngIf="countryId$ | async as countryId">
  <img *ngIf="countryId == countryIds.DK" ngSrc="/assets/images/frontPageDK.png" alt="Phone example" class="phone-image" width="473.5" height="514.5" priority />
  <img *ngIf="countryId == countryIds.SE" ngSrc="/assets/images/frontPageSE.png" alt="Phone example" class="phone-image" width="473.5" height="514.5" priority />
  <img *ngIf="countryId == countryIds.FI" ngSrc="/assets/images/frontPageFI.png" alt="Phone example" class="phone-image" width="473.5" height="514.5" priority />
  <img *ngIf="countryId == countryIds.NO" ngSrc="/assets/images/frontPageNO.png" alt="Phone example" class="phone-image" width="473.5" height="514.5" priority />
</div>
