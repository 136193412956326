import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { PhoneCodeEnum } from "@globals/enums/PhoneCodeEnum";
import { isStringNullOrEmpty } from "@globals/helper-functions";
import parsePhoneNumberFromString from "libphonenumber-js";
 
export class BiPhoneFormatTool {
  /**
   * Returns formatted phone number for display.
   * The number must be prefixed with countryCode
   */
  public static formatPhoneNumber(value: string) {
    if (value) {
      if (isStringNullOrEmpty(value) || value.includes("null")) {
        return "";
      }

      if (value.indexOf("00") == 0) {
        value = "+" + value.substring(2);
      }

      if (value.indexOf("+") !== 0) {
        value = "+" + value;
      }

      try {
        const phoneNumber = parsePhoneNumberFromString(value);
        if (phoneNumber) {
          return phoneNumber.formatInternational();
        }
        return value;
      } catch (error) {
        console.error("Error parsing phone number:", error);
        return value;
      }
    }
  }

  public static getPhoneCode(countryId: BiCountryId): PhoneCodeEnum {
    if (countryId == BiCountryId.DK) {
      return PhoneCodeEnum.DK;
    } else if (countryId == BiCountryId.DE) {
      return PhoneCodeEnum.DE;
    } else if (countryId == BiCountryId.SE) {
      return PhoneCodeEnum.SE;
    } else if (countryId == BiCountryId.EN) {
      return PhoneCodeEnum.GB;
    } else if (countryId == BiCountryId.FI) {
      return PhoneCodeEnum.FI;
    } else if (countryId == BiCountryId.NO) {
      return PhoneCodeEnum.NO;
    } else {
      return PhoneCodeEnum.DK;
    }
  }
}
