import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { CustomAnimations } from "@globals/classes/CustomAnimations";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { EnrollmentStep } from "../EnrollmentStep";

@Component({
  selector: "app-mobile-and-pin-step",
  templateUrl: "./mobile-and-pin-step.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [CustomAnimations.slideLeftRightMulti]
})
export class MobileAndPinStepComponent implements EnrollmentStep {
  public currentPage = 1;

  constructor(private router: Router, private cd: ChangeDetectorRef) {}

  onAbortClicked(): boolean {
    return false;
  }

  onBackClicked(): boolean {
    // if we are in pincode mode, go back to phone mode;
    if (this.currentPage === 2) {
      this.currentPage = 1;
      this.cd.detectChanges();
      return true;
    }
    return false;
  }

  setScrollingArea(_: ElementRef<HTMLDivElement>) {
    return;
  }

  public onLoginSuccess(enrolleeCreated) {
    if (enrolleeCreated) this.router.navigate([RouteNamesEn.mySenders]);
    else this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step2]);
  }
}
