<form class="wrapper-form" [formGroup]="formGroup">
  <div class="input-area" *ngIf="!showPincodeArea">
    <header>
      <h1 class="h1-lg" translate>enrollment.EnterPhone</h1>
      <p translate>enrollment.ReceiveCodeForVerification</p>
    </header>

    <!--Number and countrycode-->
    <en-phone-input #phoneRef class="my-6" id="enrollmentPhoneNumber" formControlName="phone" [autofocus]="true" [countryId]="countryId"></en-phone-input>

    <div class="relative">
      <en-spinner class="absolute spinner" spinnerSize="x-small" *ngIf="loading"></en-spinner>
    </div>
    <!-- Send Code-->
    <div class="two-buttons-container">
      <button pButton id="enrollmentSendCode" [label]="'enrollment.SendCode' | translate" [disabled]="phoneCtrl.invalid" (click)="sendCode(phoneRef.getValueWithCode())"></button>
    </div>
    <p class="field-error" *ngIf="error">
      {{ error }}
    </p>
  </div>

  <div class="input-area" *ngIf="showPincodeArea">
    <header>
      <h1 translate class="h1-lg">enrollment.EnterCode</h1>
      <p translate>enrollment.WeHaveSentCodeTo</p>
      <p>{{ phoneWithCountryCode | phoneFormatWithCode }}</p>
      <p class="mb-3 mt-3" translate>enrollment.ItMayTakeAFewMinutes</p>
    </header>

    <div class="flex flex-row flex-nowrap justify-content-between pin-inputs-container">
      <input
        #pin1
        id="enrollmentPin1"
        type="tel"
        pInputText
        (input)="handleInput($any($event), 0)"
        (paste)="handlePaste($event)"
        (keyup)="handleKeyup($event, 0)"
        formControlName="pin1"
        class="pin-input input-lg outer-border"
      />
      <input
        #pin2
        id="enrollmentPin2"
        type="tel"
        pInputText
        (input)="handleInput($any($event), 1)"
        (paste)="handlePaste($event)"
        (keyup)="handleKeyup($event, 1)"
        formControlName="pin2"
        class="pin-input input-lg outer-border"
      />
      <input
        #pin3
        id="enrollmentPin3"
        type="tel"
        pInputText
        (input)="handleInput($any($event), 2)"
        (paste)="handlePaste($event)"
        (keyup)="handleKeyup($event, 2)"
        formControlName="pin3"
        class="pin-input input-lg outer-border"
      />
      <input
        #pin4
        id="enrollmentPin4"
        type="tel"
        pInputText
        (input)="handleInput($any($event), 3)"
        (paste)="handlePaste($event)"
        (keyup)="handleKeyup($event, 3)"
        formControlName="pin4"
        class="pin-input input-lg outer-border"
      />
    </div>

    <p class="field-error" *ngIf="error">
      {{ error }}
    </p>

    <div *ngIf="error">
      <a class="cursor-pointer underline" role="button" (click)="sendNewCode()" translate>enrollment.SendNewCode</a>
    </div>

    <ng-container *ngIf="!error">
      <h3 class="h1-lg" translate>enrollment.NotReceivedSms</h3>
      <p translate>enrollment.CheckEnteredCorrectPhone</p>
      <div class="two-buttons-container">
        <button pButton class="p-button-outlined" (click)="showPincodeArea = false" [label]="'shared.Back' | translate"></button>
      </div>
    </ng-container>
  </div>
</form>
