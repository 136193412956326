import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/subscription-app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  let lang = sessionStorage.getItem("language");
  if (!lang) lang = getLanguageCodeByHostName();
  if (!lang) lang = "da";

  // Insert Cookie Information script
  if (lang === "se") lang = "sv"; // Cookie Information uses sv and not se
  const scriptCookie = window.document.createElement("script");
  scriptCookie.src = "https://policy.app.cookieinformation.com/uc.js";
  scriptCookie.id = "CookieConsent";
  scriptCookie.setAttribute("data-culture", lang.toUpperCase());
  document.head.appendChild(scriptCookie);

  const scriptBaseLoad = document.createElement("script");
  scriptBaseLoad.innerHTML = "";

  if (environment.googleAnalytics) {
    const ga_id = "G-T3Y4T4ZSE2"; // google analytics id
    scriptBaseLoad.innerHTML += `
      // Google Analytics
      window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${ga_id}', {
          'linker': {
          'domains': ['sms-service.dk']
          }
      });`;
  }

  if (environment.hotjar) {
    scriptBaseLoad.innerHTML += `
      <!-- Hotjar Tracking Code for app.sms-service.dk -->
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2633117,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
  }

  document.head.appendChild(scriptBaseLoad);

  if (environment.production) {
    enableProdMode();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

/**
 * Returns language code by looking at hostname. If it cannot be determined, null is returned.
 * NOTE: the Enrollment WEB app does NOT use language code as prefix like the SMS-Service WEB app, so we only check the postfix.
 */
export function getLanguageCodeByHostName() {
  if (location.hostname.indexOf("app.") !== -1) return "se";
  if (location.hostname.indexOf(".dk") !== -1) return "da";
  if (location.hostname.indexOf(".fi") !== -1) return "fi";
  if (location.hostname.indexOf(".no") !== -1) return "no";

  return null;
}
