import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { BiTranslateService } from "@globals/bi-translate";

@Injectable({
  providedIn: "root"
})
export class BiSeoService {
  constructor(
    private translator: BiTranslateService,
    private metaTagService: Meta
  ) {}

  public initialize(forSubscriptionApp: boolean) {
    this.translator.onLangChange.pipe().subscribe(() => {
      if (forSubscriptionApp) this.setupDesriptionMetaTagsForSubApp();
      else this.setupDesriptionMetaTagsForWebApp();
    });
  }

  private setupDesriptionMetaTagsForSubApp() {
    this.metaTagService.addTags([
      { name: "description", content: this.translator.instant("enrollment.FrontPageSubHeader") },
      { property: "og:description", content: this.translator.instant("enrollment.FrontPageSubHeader") }
    ]);
  }

  private setupDesriptionMetaTagsForWebApp() {
    const description = this.translator.instant("app.meta.WebAppDescription");
    this.metaTagService.addTags([
      { name: "description", content: description },
      { property: "og:description", content: description }
    ]);
  }
}
