import { Injectable } from "@angular/core";
type EnrolleeTrackingInfo = {
  enrolleeLandingPageUrl;
  enrolleeReferrerUrl;
};

/**
 * Service for managing the info we wanna track on first time subscribers/enrollees.
 * This is using localStorage so that different storages for different urls can be used at the same time (if that could happen).
 */
@Injectable({
  providedIn: "root"
})
export class EnrolleeInfoTrackingService {
  public setTrackingInfo(landingPageUrl: string, referrerUrl: string) {
    // The "senderId" is already cached in session storage by now (happens in SubscriptionAppComponent)
    // so we don't need to do it again here
    localStorage.setItem(
      "enrolleeTrackingInfo",
      JSON.stringify({
        enrolleeLandingPageUrl: landingPageUrl,
        enrolleeReferrerUrl: referrerUrl
      })
    );
  }

  public clearTrackingInfo() {
    localStorage.removeItem("enrolleeTrackingInfo");
  }

  public getTrackingInfo(): EnrolleeTrackingInfo {
    const rawData = localStorage.getItem("enrolleeTrackingInfo");
    if (!rawData) return undefined;

    const infoFromLocalStorage = JSON.parse(localStorage.getItem("enrolleeTrackingInfo")) as EnrolleeTrackingInfo;

    return <EnrolleeTrackingInfo>{
      enrolleeLandingPageUrl: infoFromLocalStorage.enrolleeLandingPageUrl,
      enrolleeReferrerUrl: infoFromLocalStorage.enrolleeReferrerUrl
    };
  }
}
