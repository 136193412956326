import { Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class EnMonitoringService {
  appInsights: ApplicationInsights;
  constructor() {
    if (environment.production && environment.appInsights.enabled) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: environment.appInsights.instrumentationKey,
          enableAutoRouteTracking: true, // option to log all route changes
          disableCookiesUsage: true
        }
      });
      this.appInsights.loadAppInsights();
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call manually
    if (this.appInsights)
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (this.appInsights) this.appInsights.trackEvent({ name: name }, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    if (this.appInsights) this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    if (this.appInsights)
      this.appInsights.trackException({
        exception: exception,
        severityLevel: severityLevel
      });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (this.appInsights) this.appInsights.trackTrace({ message: message }, properties);
  }
}
