import { Pipe, PipeTransform } from "@angular/core";
import { BiCountryId, BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";

/**
 * Pipe that transforms a country- or languageID into an image src string for the corresponding flag icon image.
 */
@Pipe({ name: "biCountryToIconSrc" })
export class BiCountryToIconSrcPipe implements PipeTransform {
  transform(value: BiCountryId | BiLanguageId): any {
    switch (value) {
      case BiCountryId.DK:
        return "/StaticFiles/images/flag-icons/dk.png";
      case BiCountryId.SE:
        return "/StaticFiles/images/flag-icons/se.png";
      case BiCountryId.EN:
        return "/StaticFiles/images/flag-icons/en.png";
      case BiCountryId.FI:
        return "/StaticFiles/images/flag-icons/fi.png";
      case BiCountryId.NO:
        return "/StaticFiles/images/flag-icons/no.png";
      default:
        return undefined;
    }
  }
}
