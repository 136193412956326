import { CommonModule } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { EnLocalizationHelperService } from "@core-sub/utility-services/en-localization-helper.service";
import { BiTranslateDirective, BiTranslatePipe, BiTranslateService } from "@globals/bi-translate";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { isStringNullOrEmpty } from "@globals/helper-functions";
import { StreetNameReadModel } from "@globals/openapi-models/model/streetNameReadModel";
import { BiAddressServiceBase } from "@globals/services/address-base.service";
import { BiSearchBaseService } from "@globals/services/bi-search-base.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AutoComplete, AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextModule } from "primeng/inputtext";
import { Observable, Subscription, debounceTime, distinctUntilChanged, filter, lastValueFrom, map, of, take, tap } from "rxjs";
import { EnrollmentAddressDtoExt } from "./EnrollmentAddressDtoExt";

@UntilDestroy()
@Component({
  selector: "en-address-selector",
  templateUrl: "./en-address-selector.component.html",
  styleUrls: ["./en-address-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule, InputTextModule, InputMaskModule, AutoCompleteModule, BiTranslatePipe, BiTranslateDirective],
  providers: [BiSearchBaseService]
})
export class EnAddressSelectorComponent implements OnInit {
  @ViewChild("houseNumberSearch") houseNumberSearchAutoComplete: AutoComplete;

  @Input() countryId: BiCountryId;
  @Output() onAddressSelected = new EventEmitter<EnrollmentAddressDtoExt>();

  public citySelected = false;

  /**
   * Autocomplete suggestions - filtered using the "streetsInZip"
   */
  public filteredStreets$: Observable<Array<StreetNameReadModel>> = of([]);

  /**
   * BehaviorSubject caching a list of street objects for a zipcode. The objects has street info containing street name and
   * a boolean telling whether there are any house numbers on that street.
   */
  private streetsInZip: StreetNameReadModel[];
  private previousStreetId: number;

  public streetHouseNumbers: Array<EnrollmentAddressDtoExt> = [];
  public suggestionsHouseNumber$: Observable<EnrollmentAddressDtoExt[]> = of([]);

  public addressForm: FormGroup;

  public zipcodeInputMaskConfig: string;
  public zipcodeRequiredLength: number;
  private getCitySubscription: Subscription;

  constructor(
    private enrollmentService: EnrollmentService,
    private addressService: BiAddressServiceBase,
    private cd: ChangeDetectorRef,
    private translator: BiTranslateService,
    private localizor: EnLocalizationHelperService,
    private searchService: BiSearchBaseService
  ) {}

  public ngOnInit() {
    this.initForm();
    this.zipcodeRequiredLength = this.localizor.getLanguageConfigByCountry(this.countryId).zipcodeLength;
  }

  //#region FormControl getters
  public get zipCodeControl() {
    return this.addressForm.get("zipCode");
  }

  public get cityControl() {
    return this.addressForm.get("city");
  }

  public get streetControl() {
    return this.addressForm.get("street");
  }

  private get houseNumberControl() {
    return this.addressForm.get("houseNumber");
  }
  //#endregion

  private initForm() {
    this.addressForm = new FormGroup({
      zipCode: new FormControl(null, control => (control.dirty && this.cityControl.value === "" ? { unknownZipCode: true } : null)),
      city: new FormControl({ value: "", disabled: true }),
      street: new FormControl({ value: "", disabled: true }),
      houseNumber: new FormControl({ value: undefined, disabled: true })
    });

    this.zipCodeControl.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000), untilDestroyed(this)).subscribe(value => this.zipCodeChanged(value));
  }

  private zipCodeChanged(value: string) {
    if (this.getCitySubscription) this.getCitySubscription.unsubscribe();
    if (isStringNullOrEmpty(this.zipCodeControl.value)) {
      this.streetControl.disable();
      return;
    }

    this.cityControl.setValue(this.translator.instant("shared.Loading"));
    this.streetControl.disable();
    this.houseNumberControl.disable();
    this.citySelected = false;

    this.getCitySubscription = this.addressService
      .getCityName(this.countryId, Number(value))
      .pipe(untilDestroyed(this))
      .subscribe({
        next: city => {
          this.checkAndFixZipcodeFormatting();
          this.zipCodeControl.setErrors(null);
          this.cityControl.setValue(city);
          this.citySelected = true;
          if (!isStringNullOrEmpty(city)) this.getStreetNames().subscribe();

          this.cd.detectChanges();
        },
        error: (error: HttpErrorResponse) => {
          this.checkAndFixZipcodeFormatting();

          if (error.status === 404) {
            this.zipCodeControl.setErrors({ unknownZipCode: true }, { emitEvent: true });
            this.cityControl.setValue("");
            this.cd.detectChanges();
          }
        }
      });
    this.streetControl.setValue(null);
    this.resetHouseNumber();
  }

  private numbersRegex = /\d+\w*(,?\s*[\d\w]+\.?)*$/;
  private autofilledHouseNumber = "";

  private getStreetNames() {
    return this.searchService.getStreetNames(this.zipCodeControl.value, this.countryId).pipe(
      untilDestroyed(this),
      tap(streets => {
        this.streetControl.enable();
        this.streetsInZip = streets;
      })
    );
  }

  public filterStreets(streetQuery: string) {
    if (streetQuery && streetQuery.length > 0) {
      const cleaned = streetQuery
        .replace(this.numbersRegex, m => {
          this.autofilledHouseNumber = m;
          return "";
        })
        .trim();

      this.filteredStreets$ = of(this.streetsInZip).pipe(
        filter(s => s.length > 0),
        take(1),
        map(streets => streets.filter(s => s.streetName.toLowerCase().lastIndexOf(cleaned.toLowerCase(), 0) === 0)),
        tap(streets => {
          if (streets?.length === 1) {
            this.streetControl.setValue(streets[0]);
            this.streetSelected(streets[0]);
          }
        })
      );
    } else {
      this.filteredStreets$ = of([]);
    }
  }

  public streetSelected(selectedStreet: StreetNameReadModel) {
    if (this.previousStreetId && selectedStreet.streetId === this.previousStreetId) return;
    else this.previousStreetId = selectedStreet.streetId;

    this.resetHouseNumber();
    this.houseNumberSearchAutoComplete.loading = true;

    this.enrollmentService.getAddresses(this.countryId, this.zipCodeControl.value, selectedStreet.streetId).subscribe(addresses => {
      this.streetHouseNumbers = addresses;
      const autofilled = addresses.find(n => n.houseNumberDisplayName === this.autofilledHouseNumber);
      if (autofilled) {
        this.houseNumberControl.setValue(autofilled);
        this.houseNumberSelected(autofilled);
      }
      this.houseNumberControl.enable();
      this.houseNumberSearchAutoComplete.loading = false;
    });
  }

  public streetCleared() {
    this.resetHouseNumber();
  }

  /**
   * In Finland, we require zipcode to be prefixed with "0"s and always be 5 digits long
   * This function checks country and zipcode value and insert leading "0"s if necessary
   */
  private checkAndFixZipcodeFormatting() {
    if (this.countryId === BiCountryId.FI && this.zipCodeControl.value.toString().length < this.zipcodeRequiredLength) {
      let zeroPrefixedZip = "0" + this.zipCodeControl.value;
      if (zeroPrefixedZip.length < this.zipcodeRequiredLength) zeroPrefixedZip = "0" + zeroPrefixedZip;
      this.zipCodeControl.setValue(zeroPrefixedZip, { emitEvent: false });
    }
  }

  public autoCompleteHouseNumberSearch(query: string) {
    this.suggestionsHouseNumber$ = of(this.streetHouseNumbers.filter(h => h.houseNumberDisplayName.indexOf(query) >= 0));
  }

  public houseNumberSelected(e: EnrollmentAddressDtoExt) {
    this.onAddressSelected.emit({
      countryId: this.countryId,
      ...e
    });
  }

  public houseNumberCleared() {
    this.clearValue();
  }

  private resetHouseNumber() {
    this.streetHouseNumbers = [];
    this.houseNumberControl.disable();
    this.houseNumberControl.setValue(null);
    this.clearValue();
  }

  private clearValue() {
    this.onAddressSelected.emit(null);
  }

  public async setSelectedAddress(value: EnrollmentAddressDtoExt) {
    if (value === null) {
      this.zipCodeControl.setValue(null);
      this.cityControl.setValue("");
      this.citySelected = false;
      this.streetControl.setValue(null);
      this.resetHouseNumber();
    } else {
      this.zipCodeControl.setValue(value.zipCode, { emitEvent: false });
      this.cityControl.setValue(value.city);
      if (!this.streetsInZip) await lastValueFrom(this.getStreetNames());
      this.streetControl.enable();
      this.streetControl.setValue(value);
      this.streetSelected(value);
      this.houseNumberControl.setValue(value);
      this.houseNumberSelected(value);
    }
    this.cd.detectChanges();
  }

  public scrollOneDown(e: Event & { overlay?: HTMLElement }) {
    if (e?.overlay) e.overlay.getElementsByClassName("p-autocomplete-panel")[0].scrollTop = 1;
  }
}
