import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "pages" })
export class EnPagesPipe implements PipeTransform {
  transform(value: any[], currentPage = 1, pages = 2) {
    const length = value.length;
    const pageSize = Math.ceil(length / pages);

    return value.slice((currentPage - 1) * pageSize, pageSize * currentPage);
  }
}
