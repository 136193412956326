import { catchError } from "rxjs/operators";
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { EnAddressSelectorComponent } from "@shared-sub/components/en-address-selector/en-address-selector.component";
import { EnrollmentStepsSharedService } from "../enrollment-steps.shared.service";
import { of } from "rxjs";
import { EnrollmentAddressDtoExt } from "@shared-sub/components/en-address-selector/EnrollmentAddressDtoExt";

@UntilDestroy()
@Component({
  selector: "enter-address",
  templateUrl: "./enter-address.component.html",
  styleUrls: ["./enter-address.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnterAddressComponent implements OnInit, AfterViewInit {
  @ViewChild("address") addressControl: EnAddressSelectorComponent;

  @Output() enrolleeName: string;

  public informationFound: boolean;
  public selectedAddress: EnrollmentAddressDtoExt;
  public countryId: BiCountryId = BiCountryId.Default;

  constructor(private router: Router, private enrollmentStepsSharedService: EnrollmentStepsSharedService, private globalStateService: GlobalStateAndEventsServiceEn) {}

  ngOnInit() {
    this.countryId = this.globalStateService.getCurrentStateValue().countryId;
    const state = this.enrollmentStepsSharedService.getCurrentStateValue();
    if (state && state.address) {
      this.enrolleeName = state.address.enrolleeName;
      this.selectedAddress = state.address;
    } else {
      this.enrollmentStepsSharedService
        .refreshOperatorInfo(this.countryId)
        .pipe(
          untilDestroyed(this),
          catchError(() => of(undefined))
        )
        .subscribe(data => {
          if (data) {
            this.informationFound = true;
            this.enrolleeName = data.name;
            this.addressControl.setSelectedAddress(data.address);
          }
        });
    }
  }

  ngAfterViewInit() {
    if (this.selectedAddress) {
      this.addressControl.setSelectedAddress(this.selectedAddress);
    }
  }

  public navigateBack(_e: MouseEvent) {
    this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step1]);
  }

  public navigateForward(_e: MouseEvent) {
    const newState = {
      ...this.enrollmentStepsSharedService.getCurrentStateValue(),
      address: { ...this.selectedAddress, enrolleeName: this.enrolleeName }
    };

    this.enrollmentStepsSharedService.setStateAndUseSessionStorage(newState);
    this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step3]);
  }

  public clearInformation(e: MouseEvent) {
    this.informationFound = false;
    this.enrolleeName = "";
    this.addressControl.setSelectedAddress(null);
    e.preventDefault();
  }

  public addressSelected(address: EnrollmentAddressDtoExt) {
    this.selectedAddress = address;
  }
}
