import { Injectable } from "@angular/core";
import { WindowSessionStorageNamesEn } from "@core-sub/WindowSessionStorageNamesEn";
import { getLanguageCodeByLanguageId } from "@globals/helper-functions";
import { EnrollmentTokenModelExt } from "@models-sub/ext/EnrollmentTokenModelExt";
import { SenderExt } from "@models-sub/ext/SenderExt";

@Injectable({ providedIn: "root" })
export class EnrollmentAuthenticationService {
  private _enrollmentTokenModel: EnrollmentTokenModelExt;
  private _sender: SenderExt;
  private _languageCode: string;

  constructor() {
    this._enrollmentTokenModel = JSON.parse(window.sessionStorage.getItem(WindowSessionStorageNamesEn.enrollmentTokenModel));
    this._languageCode = window.sessionStorage.getItem(WindowSessionStorageNamesEn.language);
    this._sender = JSON.parse(window.sessionStorage.getItem(WindowSessionStorageNamesEn.sender));
  }

  public logout() {
    this.clearData();
  }

  public get enrollmentTokenModel(): EnrollmentTokenModelExt {
    return this._enrollmentTokenModel;
  }

  public get sender(): SenderExt {
    return this._sender;
  }

  public languageCode(): string {
    return this._languageCode;
  }

  public saveTokenModel(tokenModel: EnrollmentTokenModelExt) {
    this._enrollmentTokenModel = tokenModel;
    window.sessionStorage.setItem(WindowSessionStorageNamesEn.enrollmentTokenModel, JSON.stringify(this._enrollmentTokenModel));
    this.saveLanguage(getLanguageCodeByLanguageId(tokenModel.languageId));
  }

  public updateEnrolleeCreationDate() {
    this._enrollmentTokenModel.enrolleeCreationDate = new Date().toString();
    this.saveTokenModel(this._enrollmentTokenModel);
  }

  /**
   * Saves language locally as well as in session storage
   */
  public saveLanguage(languageCode: string) {
    this._languageCode = languageCode;
    window.sessionStorage.setItem(WindowSessionStorageNamesEn.language, languageCode);
  }

  public saveSender(sender: SenderExt) {
    this._sender = sender;
    if (sender) window.sessionStorage.setItem(WindowSessionStorageNamesEn.sender, JSON.stringify(this._sender));
    else window.sessionStorage.removeItem(WindowSessionStorageNamesEn.sender);
  }

  /**
   * Clears both tokens in this service as well as in localstorage
   */
  public clearData() {
    this._enrollmentTokenModel = null;
    window.sessionStorage.removeItem(WindowSessionStorageNamesEn.enrollmentTokenModel);
  }
}
