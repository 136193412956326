import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { EnrolleeService } from "@core-sub/services/enrollee.service";

/**
 * Global route guard for application. Must be used with componentless route that uses canActivateChild and defines child routes
 */
@Injectable()
export class AppCanActivateGuard implements CanActivate, CanActivateChild {
  constructor(private enrolleeService: EnrolleeService, private router: Router) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    if (this.enrolleeService.enrolleeHasLoggedIn()) return of(true);
    else {
      this.router.navigate([""]);
      return of(false);
    }
  }
}
