import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRouting } from "@globals/classes/ApiRouting";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { StreetNameReadModel } from "@globals/openapi-models/model/streetNameReadModel";

@Injectable()
export class BiSearchBaseService {
  constructor(protected http: HttpClient) {}

  /**
   * Returns a list of objects representing streets under a zipcode in a country.
   */
  public getStreetNames(zipcode: number, countryId: BiCountryId) {
    return this.http.get<StreetNameReadModel[]>(ApiRouting.searchEndpoints.get.getStreetNames, {
      params: {
        zipcode,
        countryId
      }
    });
  }
}
