import { NgModule } from "@angular/core";
import { EnPagesPipe } from "./en-pages.pipe";
import { EnLinkPipe } from "./en-link.pipe";
import { EnAddressFormatPipe } from "./en-address-format.pipe";
import { GlobalPipesModule } from "@globals/pipes/global-pipes.module";

@NgModule({
  declarations: [EnPagesPipe, EnLinkPipe, EnAddressFormatPipe],
  exports: [EnPagesPipe, EnLinkPipe, EnAddressFormatPipe],
  providers: [EnAddressFormatPipe],
  imports: [GlobalPipesModule]
})
export class EnPipesModule {}
