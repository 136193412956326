import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class BiTranslateService extends TranslateService {
  public get(key: string, interpolateParams?: { [key: string]: string | number }): Observable<string> {
    if (!key) throw new Error(`Parameter "key" required`);

    return super.get(key.toUpperCase(), interpolateParams);
  }

  /**
   * Get translations of multiple keys
   * @param key MUST BE UPPERCASE!!
   * @returns Observable with an object of keys being the translation keys and the values being the translated string
   */
  public getMulti(key: Array<string>, interpolateParams?: { [key: string]: string | number }): Observable<{[key: string]: string}> {
    if (!key) throw new Error(`Parameter "key" required`);

    return super.get(
      key.map(k => k.toUpperCase()),
      interpolateParams
    );
  }

  public instant(key: string, interpolateParams?: { [key: string]: string | number }): string {
    if (!key) throw new Error(`Parameter "key" required`);

    return super.instant(key.toUpperCase(), interpolateParams);
  }

  /**
   * Get translations of multiple keys
   * @param key MUST BE UPPERCASE!!
   * @returns An object of keys being the translation keys and the values being the translated string
   */
  public instantMulti(keys: Array<string>, interpolateParams?: { [key: string]: string | number }): { [key: string]: string } {
    if (!keys) throw new Error(`Parameter "key" required`);

    return super.instant(
      keys.map(k => k.toUpperCase()),
      interpolateParams
    );
  }
}
