export class ApiRoutesEn {
  private static apiEndpoint = "api/";

  private static commonEndpoint = ApiRoutesEn.apiEndpoint + "Common/";

  private static enrollmentEndpoint = ApiRoutesEn.apiEndpoint + "Enrollment/";

  public static getDashboardData = ApiRoutesEn.apiEndpoint + "EnrollmentAdmin/GetDashboardEnrollmentData";
  public static getMapLayers = ApiRoutesEn.apiEndpoint + "Map/GetMapLayers";

  /**
   * Routes for CommonController
   */
  public static commonRoutes = {
    errorLogging: {
      logJavascriptException: ApiRoutesEn.commonEndpoint + "LogJavascriptException",
      getJavascriptExceptions: ApiRoutesEn.commonEndpoint + "GetJavascriptExceptions"
    },
    loadSensitivePage: ApiRoutesEn.commonEndpoint + "LoadSensitivePage",
    canLoadSensitivePage: ApiRoutesEn.commonEndpoint + "CanLoadSensitivePage",

    validateGoogleCaptchaToken: ApiRoutesEn.commonEndpoint + "ValidateGoogleCaptchaToken",

    localizationRoutes: {
      getAllResources: ApiRoutesEn.commonEndpoint + "Resources"
    }
  };
  /**
   * Routes for EnrollmentController
   */
  public static enrollmentRoutes = {
    requestPinCodeAttempt: ApiRoutesEn.enrollmentEndpoint + "RequestPinCodeAttempt",
    verifyPinCode: ApiRoutesEn.enrollmentEndpoint + "VerifyPinCode",
    sendATip: ApiRoutesEn.enrollmentEndpoint + "SendATip",
    signUp: ApiRoutesEn.enrollmentEndpoint + "SignUp",
    addEnrolleeAddress: ApiRoutesEn.enrollmentEndpoint + "AddEnrolleeAddress",
    updateLanguage: ApiRoutesEn.enrollmentEndpoint + "UpdateLanguage",

    loginCookie: ApiRoutesEn.enrollmentEndpoint + "LoginCookie",
    logout: ApiRoutesEn.enrollmentEndpoint + "Logout",
    get: {
      getInitialData: ApiRoutesEn.enrollmentEndpoint + "GetInitialData",
      getEnrolleeInfo: ApiRoutesEn.enrollmentEndpoint + "GetEnrolleeInfo",
      getCity: ApiRoutesEn.enrollmentEndpoint + "GetCity",
      getAddresses: ApiRoutesEn.enrollmentEndpoint + "GetAddresses",
      getOperatorInfo: ApiRoutesEn.enrollmentEndpoint + "GetOperatorInfo",
      getSenderBySenderId: ApiRoutesEn.enrollmentEndpoint + "GetSenderBySenderId",
      getSenderBySlug: ApiRoutesEn.enrollmentEndpoint + "GetSenderBySlug",
      getRelevantSenders: ApiRoutesEn.enrollmentEndpoint + "GetRelevantSenders",
      searchSenders: ApiRoutesEn.enrollmentEndpoint + "SearchSenders",
      getMySendersModel: ApiRoutesEn.enrollmentEndpoint + "GetMySendersModel",
      getSenderLogo: ApiRoutesEn.enrollmentEndpoint + "GetSenderLogo"
    },
    create: {
      addEnrollments: ApiRoutesEn.enrollmentEndpoint + "AddEnrollments"
    },
    update: {
      updateEnrolleeInfo: ApiRoutesEn.enrollmentEndpoint + "UpdateEnrolleeInfo",
      updateEnrolleeAddressName: ApiRoutesEn.enrollmentEndpoint + "UpdateEnrolleeAddressName"
    },
    delete: {
      deleteEnrollment: ApiRoutesEn.enrollmentEndpoint + "DeleteEnrollment",
      deleteEnrolleeAddress: ApiRoutesEn.enrollmentEndpoint + "DeleteEnrolleeAddress"
    }
  };
}
