<div class="wrapper" role="navigation">
  <img class="main-logo cursor-pointer" (click)="onMainLogoClicked.emit()" [ngSrc]="logoUrl" height="64" width="300" priority alt="SMS-Service Logo" />
  <div class="hidden xl:inline-flex align-items-center w-full justify-content-end mr-3">
    <a class="toolbar-menu-item-link cursor-pointer" routerLinkActive="toolbar-menu-item-link--active" [routerLink]="RouteNames.aboutPage" translate>enrollment.AboutPageTitle</a>

    <a class="toolbar-menu-item-link cursor-pointer" routerLinkActive="toolbar-menu-item-link--active" [routerLink]="RouteNames.termsAndConditions" translate>app.TermsAndConditions</a>

    <a class="toolbar-menu-item-link cursor-pointer" routerLinkActive="toolbar-menu-item-link--active" [routerLink]="RouteNames.privacyPolicy" translate>shared.PrivacyPolicy</a>

    <en-language-selector #languageSelectorDesktop class="mx-5 language-selector" (onLanguageSelected)="onChangeLanguage.emit($event)"></en-language-selector>
    <button pButton class="p-button-outlined login-out-button" [label]="loginButtonLabel() | translate" (click)="loginOrOut()"></button>
  </div>
  <div class="burger-menu xl:hidden">
    <button pButton class="p-button-text" icon="pi pi-bars pointer" (click)="showSideMenu = !showSideMenu"></button>
  </div>
</div>

<p-sidebar [(visible)]="showSideMenu" position="right" class="xl:hidden" [style]="{ 'min-width': '21rem' }">
  <div class="flex flex-column align-items-center overflow-hidden">
    <img class="main-logo-plain mb-3" ngSrc="assets/images/SMS-Service_logo_PLAIN.svg" height="48" width="300" alt="SMS-Service Logo" />
    <en-language-selector #languageSelectorMobile class="mb-5" (onLanguageSelected)="onChangeLanguage.emit($event)"></en-language-selector>
    <button pButton class="p-button-text" [routerLink]="RouteNames.aboutPage" (click)="this.showSideMenu = false" [label]="'enrollment.AboutPageTitle' | translate" translate></button>
    <button pButton class="p-button-text" [routerLink]="RouteNames.termsAndConditions" (click)="this.showSideMenu = false" [label]="'app.TermsAndConditions' | translate" translate></button>
    <button pButton class="p-button-text" [label]="'shared.PrivacyPolicy' | translate" (click)="showPrivacyPolicy()"></button>

    <button pButton class="login-button" [label]="loginButtonLabel() | translate" (click)="loginOrOut()"></button>
  </div>
</p-sidebar>
