import { NgModule } from "@angular/core";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { SharedModule } from "@shared-sub/shared.module";
import { InputTextareaModule } from "primeng/inputtextarea";
import { SendATipDialogContentComponent } from "./send-a-tip-dialog-content.component";

@NgModule({
  imports: [SharedModule, InputTextareaModule, BiTranslatePipe, BiTranslateDirective],
  declarations: [SendATipDialogContentComponent],
  exports: [SendATipDialogContentComponent]
})
export class SendATipDialogContentModule {}
