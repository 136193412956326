<en-dialog-frame (onBack)="goBack()" (onClose)="onAbortClicked()" (onScrollable)="this.scrollingArea = $event">
  <p-steps [model]="stepItems" [readonly]="true" styleClass="center-horizontal"></p-steps>

  <div [class.hidden]="showOnCloseWarning">
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>

  <div id="close-warning-container" class="" *ngIf="showOnCloseWarning" [@fadeInOut]="{ params: { speed: 100 } }">
    <h1 translate>enrollment.Obs</h1>
    <p class="text-center" translate>enrollment.AbortSignupWarning</p>

    <p class="mt-3" translate>enrollment.AbortSignupConfirm</p>

    <div class="two-buttons-container">
      <button pButton class="inline-block p-button-outlined" [label]="'enrollment.Regret' | translate" (click)="showOnCloseWarning = false"></button>
      <div class="spacer"></div>
      <button pButton [label]="'enrollment.YesLeave' | translate" (click)="onAbortClicked()"></button>
    </div>
  </div>
</en-dialog-frame>
