/**
 * SmsService API Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PageNames = 'SubscriptionModule' | 'SubscriptionStdReceiver' | 'FakeRequestAndSavePinCode' | 'EnrollmentModule' | 'SupportPage';

export const PageNames = {
    SubscriptionModule: 'SubscriptionModule' as PageNames,
    SubscriptionStdReceiver: 'SubscriptionStdReceiver' as PageNames,
    FakeRequestAndSavePinCode: 'FakeRequestAndSavePinCode' as PageNames,
    EnrollmentModule: 'EnrollmentModule' as PageNames,
    SupportPage: 'SupportPage' as PageNames
};

