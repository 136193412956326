import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { BiStore } from "@globals/classes/BiStore";
import { BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";
import { EnrolleeDto } from "@globals/openapi-models/model/enrolleeDto";
import { EnrollmentTokenModelExt } from "@models-sub/ext/EnrollmentTokenModelExt";
import { ApiRoutesEn } from "@shared-sub/ApiRoutesEn";
import { of, tap } from "rxjs";

class EnrolleeState {
  currentLanguageId: BiLanguageId;
  currentEnrollee: EnrolleeDto;
}

@Injectable({ providedIn: "root" })
export class EnrolleeService extends BiStore<EnrolleeState> {
  constructor(
    private http: HttpClient,
    private authService: EnrollmentAuthenticationService,
    private globalStateService: GlobalStateAndEventsServiceEn
  ) {
    super(new EnrolleeState());
  }

  /**
   * For generating a security PIN code and send it to provided email and/or phone number.
   * @param pageNameId Id of the  page where the user is requesting PIN code.
   */
  public requestPinCodeAttempt(phoneNumber: string, accessToken: string) {
    const params: { [key: string]: string } = {
      phoneNumber,
      languageId: this.globalStateService.getCurrentStateValue().selectedLanguage.toString(),
      accessToken
    };

    const sender = this.authService.sender;
    if (sender) params.senderId = sender.id;

    return this.http.post(ApiRoutesEn.enrollmentRoutes.requestPinCodeAttempt, params);
  }

  public verifyPinCode(phoneNumber: string, pinCode: string) {
    const params: { [key: string]: string } = {
      phoneNumber,
      pinCode: pinCode,
      languageId: this.globalStateService.getCurrentStateValue().selectedLanguage.toString()
    };

    const sender = this.authService.sender;
    if (sender) params.senderId = sender.id;

    return this.http.post<EnrollmentTokenModelExt>(ApiRoutesEn.enrollmentRoutes.verifyPinCode, params);
  }

  public updateLanguage(languageId: number) {
    return this.http.post(ApiRoutesEn.enrollmentRoutes.updateLanguage, languageId);
  }

  /**
   * Has an Enrollee logged in?
   */
  public enrolleeHasLoggedIn(): boolean {
    return this.authService.enrollmentTokenModel !== null;
  }

  public getEnrollee() {
    if (this.state.value.currentEnrollee) return of(this.state.value.currentEnrollee);

    return this.http.get<EnrolleeDto>(ApiRoutesEn.enrollmentRoutes.get.getEnrolleeInfo, {}).pipe(tap(enrollee => this.state.next({ ...this.state.value, currentEnrollee: enrollee })));
  }

  public updateEnrollee(dto: EnrolleeDto) {
    return this.http.patch(ApiRoutesEn.enrollmentRoutes.update.updateEnrolleeInfo, dto).pipe(
      tap(() => {
        this.state.next({
          ...this.state.value,
          currentEnrollee: {
            ...this.state.value.currentEnrollee,
            name: dto.name,
            email: dto.email
          }
        });
      })
    );
  }

  public clearState() {
    this.state.next(new EnrolleeState());
  }
}
