import { ChangeDetectorRef, Directive, ElementRef } from "@angular/core";
import { areStringsEqual } from "@globals/helper-functions";
import { TranslateDirective, TranslateService } from "@ngx-translate/core";
import { Observable, of, take } from "rxjs";

@Directive({
  selector: "[translate]",
  standalone: true
})
export class BiTranslateDirective extends TranslateDirective {
  constructor(
    private translator: TranslateService,
    element: ElementRef,
    ref: ChangeDetectorRef
  ) {
    super(translator, element, ref);
  }

  updateValue(key: string, node: any, translations: any) {
    if (key) {
      // First find out out whether the translation exists
      let obs$: Observable<string>;
      if (translations) obs$ = of(this.translator.getParsedResult(translations, key.toUpperCase(), this.currentParams));
      else obs$ = this.translator.get(key.toUpperCase(), this.currentParams);

      obs$.pipe(take(1)).subscribe(val => {
        // Make sure that if the translated value is equal to the provided key (case insensitive here), then do NOT make anything uppercase!
        if (val && !areStringsEqual(key, val)) node.originalContent = key?.toUpperCase();

        super.updateValue(node.originalContent, node, translations);
      });
    }
  }
}
