import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
import { areStringsEqual, isStringNullOrEmpty } from "@globals/helper-functions";
import { TranslatePipe } from "@ngx-translate/core";
import { BiTranslateService } from "./bi-translate.service";

@Pipe({
  name: "translate",
  standalone: true,
  pure: false
})
export class BiTranslatePipe implements PipeTransform {
  private translatePipe: TranslatePipe;

  constructor(translate: BiTranslateService, _ref: ChangeDetectorRef) {
    this.translatePipe = new TranslatePipe(translate, _ref);
  }

  transform(key: string, params?: { [key: string]: string | number }): string {
    const translatedValue = !isStringNullOrEmpty(key) ? this.translatePipe.transform(key.toUpperCase(), params) : "";
    return areStringsEqual(key, translatedValue) ? key : translatedValue;
  }
}
