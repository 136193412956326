import { AnimationMetadata, animate, group, query, state, style, transition, trigger } from "@angular/animations";

/**
 * All custom animations used in the app
 * Angular IO doc for animations: https://angular.io/api/animations/transition
 * About parameters that can be specificed for an animation (as well as many  other things): https://www.yearofmoo.com/2017/06/new-wave-of-animation-features.html
 */
export class CustomAnimations {
  //#region SLIDING ANIMATIONS
  private static leftSlide: AnimationMetadata[] = [
    query(
      ":enter, :leave",
      style({
        position: "absolute",
        width: "{{ width }}",
        zIndex: "{{ zIndex }}"
      }),
      { params: { zIndex: "1500", width: "100%" } }
    ),
    query(":leave", style({ transform: "translateX(0%)", opacity: 1 })), // start at x pos 0
    query(":enter", style({ transform: "translateX(90%)", opacity: 0.2 })), // start from the right

    // run animation in parallel
    group([
      query(":leave", animate("400ms ease-out", style({ transform: "translateX(-100%)", opacity: 0.2 }))), // go out from middle -> left
      query(":enter", animate("400ms ease-in", style({ transform: "translateX(0%)", opacity: 1 }))) // come in from right -> left
    ])
  ];

  private static rightSlide: AnimationMetadata[] = [
    query(
      ":enter, :leave",
      style({
        position: "absolute",
        width: "{{ width }}",
        zIndex: "{{ zIndex }}"
      }),
      { params: { zIndex: "1500", width: "100%" } }
    ),
    query(":enter", style({ transform: "translateX(-90%)", opacity: 0.2 })), // start at x pos 0
    query(":leave", style({ transform: "translateX(0%)", opacity: 1 })), // start from the right

    // run animation in parallel
    group([
      query(":enter", animate("400ms ease-out", style({ transform: "translateX(0%)", opacity: 1 }))), // come in from left ->  middle
      query(":leave", animate("400ms ease-in", style({ transform: "translateX(100%)", opacity: 0.2 }))) // go out from middle -> right
    ])
  ];

  /**
   * Animates entering and leaving content with a left or right slide effect depending on an index. This index is some number
   * that either increment or decrements. An increment will make a left slide (meaning content to the right should become visible), a decrement a right
   * slide (left content visible). This makes it possible to have multiple elements in a slide-container.
   * Usage example:
   * <div [@slideLeftRightMulti]="someIndex">
   *      <p *ngIf="">I'm being slided in when I become visible! </p>
   *      <p *ngIf="">I'm being slided in when I become visible! </p>
   *      <p *ngIf="">I'm being slided in when I become visible! </p>
   * </div>
   * <button (click)="someIndex -= someIndex">Prev</button> <button (click)="someIndex += someIndex">Next</button>
   */
  public static slideLeftRightMulti = trigger("slideLeftRightMulti", [transition(":increment", CustomAnimations.leftSlide), transition(":decrement", CustomAnimations.rightSlide)]);
  //#endregion

  /**
   * Animation for folding in/out an element by adjusting height while also adding fade-effect. Used, for example, for AddressSearchItems, StdReceiverSearchItems etc..
   * Usage: add [@foldInOut]="someProperty" to an element which height should be animated. "someProperty" must be a string toggling between "in" and "out"
   */
  public static zeroToAutoHeightAnimation = trigger("foldInOut", [
    state(
      "out",
      style({
        height: "*", // instead of using "auto", this works better
        margin: "*",
        opacity: 1
      })
    ),
    state(
      "in",
      style({
        height: "0px",
        opacity: "0",
        overflow: "hidden",
        margin: "0"
      })
    ),
    transition("out <=> in", animate("400ms ease-in-out"))
  ]);

  public static zeroToAutoWidthAnimation = trigger("autoWidth", [
    transition(
      ":enter",
      [
        style({
          width: "0px",
          opacity: "0",
          overflow: "hidden",
          margin: "0"
        }),
        animate("400ms linear", style({ opacity: "1", width: "{{width}}", margin: "*" }))
      ],
      { params: { width: "*" } }
    ),
    transition(
      ":leave",
      [
        style({
          margin: "*",
          width: "{{width}}",
          opacity: 1
        }),
        animate(
          "400ms linear",
          style({
            width: "0px",
            opacity: "0",
            margin: "0"
          })
        )
      ],
      { params: { width: "*" } }
    )
  ]);

  //#region FADE ANIMATIONS
  /**
   * Animation used for only fading IN an element and not out.
   */
  public static fadeIn = trigger("fadeIn", [transition(":enter", [style({ opacity: 0 }), animate(500, style({ opacity: 1 }))])]);

  public static fadeInOut = trigger("fadeInOut", [
    transition(":enter", [style({ opacity: "0" }), animate("{{speed}}ms ease-out", style({ opacity: "1" }))], {
      params: { speed: 400 }
    }),
    transition(":leave", [style({ opacity: "1" }), animate("{{speed}}ms ease-out", style({ opacity: "0" }))], {
      params: { speed: 400 }
    })
  ]);

  /**
   * For adding fade in animation with small slide from top. Added to entering and leaving child elements of a container. Typically for elements using *ngIf or *ngFor directives
   * Usage example:
   * <div *ngIf="somePropertyOrWhatEver" @fadeInDown>
   *      <p>I'm being animated when I become visible </p>
   * </div>
   */
  public static fadeInDown = trigger("fadeInDown", [
    transition(":enter", [style({ opacity: "0", transform: "translateY(-10px)" }), animate("{{speed}} ease-in", style({ opacity: "1", transform: "translateY(0px)" }))], {
      params: { speed: "400ms" }
    }),
    transition(":leave", [style({ opacity: "1", transform: "translateY(0px)" }), animate("{{speed}} ease-out", style({ opacity: "0", transform: "translateY(-10px)" }))], {
      params: { speed: "400ms" }
    })
  ]);
  //#endregion
}
