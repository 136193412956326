import { Component, Input, HostBinding, OnInit } from "@angular/core";

/**
 * Spinner component - for showing a spinner! See documentation of input properties for the awesome features!
 */
@Component({
  selector: "en-spinner",
  templateUrl: "./en-spinner.component.html",
  styleUrls: ["./en-spinner.component.scss"]
})
export class EnSpinnerComponent implements OnInit {
  /**
   * Size of spinner. Default is medium
   */
  @Input() public spinnerSize: "x-small" | "small" | "medium" = "medium";

  /**
   * Set true if you want spinner to be displayed "inline-block" - for ex. when you want some text next to it. Default
   * is false meaning it will be displyed "block" (e.g. on its own line)
   */
  @Input() public inline = false;

  /**
   * Add a Spinner text to be shown
   */
  @Input() public message: string;

  /**
   * Set true if you want the opacity of the spinner background to be more visible
   */
  @Input() public highOpacity = false;

  /**
   * Will make the spinner be positioned fixed so user can always see it. Default true. On smaller pages (small height),
   * set this false but for best result
   */
  @HostBinding("class.fixed-pos")
  @Input()
  public fixedPosition = true;

  /**
   * Wether a background is shown. This is a greyed-out background with a bit transparency
   */
  @HostBinding("class.has-background")
  @Input()
  public hasBackground = false;

  /**
   * Set this to true if you want the spinner to be displayed on top of parent container content.
   * REQUIREMENT FOR USE: parent container element MUST have css "position: relative" and display property set!
   */
  @HostBinding("class.on-top")
  @Input()
  public onTopOfContainer = false;

  /**
   * Set this to true if you want the spinner to be displayed in the center of parent container content.
   * Notice: if you set this to true, the input property "onTopOfContainer" will automatically be true also, if not already set.
   * REQUIREMENT FOR USE: parent container element MUST have css "position: relative" and display property set!
   */
  @HostBinding("class.center")
  @Input()
  public centerInParent = false;

  @HostBinding("style.display") public display = "block";

  public ngOnInit() {
    if (this.centerInParent) this.onTopOfContainer = true;

    if (this.inline) this.display = "inline-block";
  }
}
