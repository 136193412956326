<!-- Phone prefix and phone number-->

<div class="p-inputgroup input-lg outer-border" [class.ng-invalid]="controlRef?.errors">
  <p-dropdown class="countrycode-selector" [options]="countryCodes" [(ngModel)]="selectedCountryCode" (onChange)="onCountryCodeChanged()"></p-dropdown>
  <input
    #input
    pInputText
    [id]="inputId"
    class="phone-input"
    [attr.aria-labelledby]="inputId"
    [class.ng-invalid]="controlRef?.errors"
    type="tel"
    [maxlength]="phoneMaxLength"
    [disabled]="disabled"
    [placeholder]="'shared.EnterNumber' | translate"
    (focus)="hasFocus = true"
    (blur)="hasFocus = false"
    (keyup.enter)="onEnter.emit()"
    [(ngModel)]="inputValue"
    (ngModelChange)="onChange(input.value)"
    (blur)="onTouched()"
  />
</div>

<!-- Errors (remember to set the validators on the FormControl instance) -->
<div *ngIf="controlRef?.errors && controlRef?.touched" role="alert" aria-atomic="true">
  <p class="field-error" *ngIf="controlRef.errors.required" translate>errorMessages.MustBeFilled</p>

  <!-- Use phoneLengthValidator here from custom validators -->
  <p class="field-error" *ngIf="controlRef.errors.phoneMin && phoneMinLength === phoneMaxLength" [translateParams]="{ length: phoneMinLength }" translate>enrollment.PhoneMustBeXLong</p>

  <p class="field-error" *ngIf="controlRef.errors.phoneMin && phoneMinLength !== phoneMaxLength" [translateParams]="{ length: phoneMinLength }" translate>enrollment.PhoneMustBeMinXLong</p>

  <p class="field-error" *ngIf="controlRef.errors.phoneMax" [translateParams]="{ length: phoneMaxLength }" translate>enrollment.MaxXDigits</p>

  <p class="field-error" *ngIf="controlRef.errors.digitsOnly" translate>enrollment.OnlyDigits</p>
</div>
