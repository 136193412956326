import { BiCountryId, BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";

export class GlobalStateEn {
  public countries: Array<{ countryId: BiCountryId; name: string }> = [];

  /**
   * Selected application language
   */
  public selectedLanguage: BiLanguageId;

  /**
   * Country ID based on host name
   */
  public countryId: BiCountryId;
  /**
   * The route that user was trying to visit before he/she was logged out as a result of an expired refresh token.
   * This makes it possible to navigate back to where user left of after logging in again.
   */
  public routeAfterLogin?: string;

  public clientIp: string;

  public showTopMenubar = true;
  public showOverlay = false;

  public constructor(countries: Array<{ countryId: BiCountryId; name: string }>) {
    this.countries = countries;
  }
}
