<div class="wrapper text-center">
  <button pButton class="p-button-rounded p-button-text ml-auto absolute close-icon-button" icon="pi pi-times" (click)="onLogOutClicked()"></button>

  <h2 translate>shared.ThankYou</h2>

  <div class="pb-2" style="max-width: 410px" [innerHTML]="'enrollment.EnrollmentFinishedMessage' | translate"></div>

  <en-two-buttons
    [leftButtonLabel]="'enrollment.NoLogMeOut' | translate"
    [rightButtonLabel]="'shared.Proceed' | translate"
    [leftButtonColor]="'blue'"
    [rightButtonColor]="'red'"
    (onLeftButtonClicked)="onLogOutClicked()"
    (onRightButtonClicked)="proceedToMySenders()"
  ></en-two-buttons>
</div>
